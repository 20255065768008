import { Module } from "vuex";
import { AddOrUpdateIncomeRequest, Income } from "@/models/incomes";
const incomeTabModule: Module<(any), any> = {
  namespaced: true,
  state: {
    incomes: [] as Income[],
    originalIncome: {},
    updatedIncome: {},
    incomeDocuments: [],
    currentIncomeDocument: {},
    newIncome: {}
  },
  getters: {
    originalIncome: (state: any): any => {
      return state.originalIncome;
    },
    updatedIncome: (state: any): any => {
      return state.updatedIncome;
    },
    incomes: (state: any): any => {
      return state.incomes;
    },
    incomeDocuments: (state: any): any => {
      return state.incomeDocuments;
    },
    // addIncomeRequest: (state: any): AddOrUpdateIncomeRequest => {
    //   const addIncomeRequest: AddOrUpdateIncomeRequest = {
    //     applicantId: props?.applicant?.id,
    // applicantDocumentId: income.applicantDocumentId,
    // incomeYearTypeIdId: parseInt(income?.incomeYearTypeIdId),
    // incomeTypeId: income.incomeTypeId,
    // incomeAmount: income.incomeAmount,
    // incomePeriodTypeId: income.incomePeriodTypeId,
    // incomeDate: null,
    // isVerified: true,
    // incomeStatusTypeId: 1,
    // applicantEmploymentId: income.applicantEmploymentId,
    // applicantEmploymentName: income.applicantEmploymentName,
    // applicantEmploymentTypeId: income.applicantEmploymentTypeId,
    //   };
    //   return addIncomeRequest;
    // }
  },
  mutations: {
    setOriginalIncome(state: any, income: Income) {
      state.originalIncome = income;
    },
    setUpdatedIncome(state: any, income: Income) {
      state.updatedIncome = income;
    },
    setIncomes(state: any, incomes: Income) {
      state.incomes = incomes;
    },
    setIncomeDocuments(state: any, incomeDocuments: Income) {
      state.incomeDocuments = incomeDocuments;
    },
    SET_NEW_INCOME(state: any, applicantId: any) {
      state.newIncome = {};
    },
    discardChanges(state: any) {
      state.updatedIncome =  state.originalIncome;
    },
    resetAllStates(state: any) {
      state.incomes = [] as Income[];
      state.originalIncome = {} as Income;
      state.updatedIncome = {} as Income;
    },
    resetOriginalIncome(state: any) {
      state.originalIncome = {} as Income;
    },
  },
  actions: {
    setOriginalIncome({ commit }, payload) {
      commit("setOriginalIncome", payload);
    },
    setUpdatedIncome({ commit }, payload) {
      commit("setUpdatedIncome", payload);
    },
    setIncomes({ commit }, payload) {
      commit("setIncomes", payload);
    },
    setIncomeDocuments({ commit }, payload) {
      commit("setIncomeDocuments", payload);
    },
    resetOriginalIncome({ commit }, payload) {
      commit("resetOriginalIncome", payload);
    },
    discardChanges({ commit }, payload) {
      commit("discardChanges", payload);
    },
    resetAllStates({ commit }, payload) {
      commit("resetAllStates", payload);
    },
  }
}
export default incomeTabModule;