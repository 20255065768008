import store from "@/store";
import useFormatter from "@/use/formatter";
import { LOSType, ProgramLimit } from "@/models/opal";

const { formatMoney, formatDecimalAsPercentage } = useFormatter();

export default function useIncomeTab(): any {
  function limitTypeName(limit: string, applicantType: string) {
    const statusAbbreviation = "CondApproved";
    return `${applicantType}${limit}${statusAbbreviation}`;
  
  }
  function getIncomeProgramLimit(applicantType: string) {
    const limit = "MinIncome";
    return limitTypeName(limit, applicantType);
  }
  function getEmploymentLimit(applicantType: string) {
    const limit = "MinEmployment";
    return limitTypeName(limit, applicantType);
  }
  function getDTIProgramLimit(applicantType: string) {
    const limit = "MaxDTI";
    return limitTypeName(limit, applicantType);
  }
  function programLimitValue(programLimit: string, dti: any, yearsOfEmployment: any, incomeLimit: any) {
    switch (programLimit) {
      case "DTI":
      case "Pricing - DTI":
        return dti
      case "Employment":
        return yearsOfEmployment
      case "Pricing - Income":
      case "Income":
        return incomeLimit
      default: return ""
    }
  }
function applicationRequirementDecision(programRequirement: any, programRequirementsDecisions: any) {
  const applicationRequirementDecision = programRequirementsDecisions?.find((r: any) => r.requirementTypeName?.toLowerCase()?.includes(`${programRequirement.name?.toLowerCase()}`));
  return applicationRequirementDecision;
}

function getIncomeLimit(application, applicantType) {
  const incomeLimitTypeName = getIncomeProgramLimit(applicantType);
  const value = application.programLimits?.find((p: ProgramLimit) => p.limitTypeName === `${incomeLimitTypeName}`)?.value;
  return value && !isNaN(value) ? formatMoney(value) : "N/A";
}

function getYearsOfEmployment(application, applicantType, incomeLimit) {
  const employmentLimit = getEmploymentLimit(applicantType);
  const years = application.programLimits?.find((p: ProgramLimit) => p.limitTypeName === `${employmentLimit}`)?.value;
  // income limit does not exist, employment limit does not exist
  if (incomeLimit === "N/A" && years === undefined) {
    return "N/A"
  } else {
    return `${years / 12} ${years/12 > 1 ? 'Years' : 'Year'}` || "N/A";
  }  
}

function getDti(application, applicantType) {
  const limitTypeName = getDTIProgramLimit(applicantType);
  const decimal = application.programLimits?.find((p: ProgramLimit) => p.limitTypeName === limitTypeName)?.value;

  return decimal ? formatDecimalAsPercentage(decimal) : "N/A";
}

async function initializeIncomeTab(application) {
  const soloApp = application?.data?.metadata?.withCoApplicants === false;
  const incomeProgramRequirements = store?.state.types["ProgramRequirements"]?.results?.filter((r: any) => r.name?.trim() === 'DTI' || r.name?.trim() === 'Employment' || r.name?.trim() === 'Income');
  const programRequirementsDecisions = application?.programRequirements;
  const applicantType = soloApp ? "Borrower" : "CoBorrower";
  const incomeLimit = getIncomeLimit(application, applicantType);
  const dti = getDti(application, applicantType);
  const yearsOfEmployment = getYearsOfEmployment(application, applicantType, incomeLimit);
  const programRequirementInformation = await setData(incomeProgramRequirements, application, incomeLimit, dti, yearsOfEmployment);
  const result = await verify(incomeProgramRequirements,programRequirementInformation);

  return result;
}
function getRequirementType(programRequirement: any) {
  const requirementType = store?.state.types["Requirements"]?.results?.find((r: any) => r.name?.toLowerCase()?.includes(`${programRequirement.name?.toLowerCase()}`));
  return requirementType;
}
async function setData(incomeProgramRequirements, application, incomeLimit, dti, yearsOfEmployment) {
  const programRequirementInformation = {} as any;
  const programRequirementsDecisions = application.programRequirements;
  const defaultApplicationRequirementDecision = store?.state?.types["RequirementStatuses"]?.results?.find((s: LOSType) => s.name?.trim() === "Open");

  incomeProgramRequirements?.forEach((pr: any) => {
    programRequirementInformation[pr.name] = {
      programRequirementTypeName: pr.name,
      applicationId: applicationRequirementDecision(pr, programRequirementsDecisions)?.applicationId || application?.data?.id,
      applicationRequirementDecisionId: applicationRequirementDecision(pr, programRequirementsDecisions)?.id,
      programRequirementLimit: programLimitValue(pr.name, dti, yearsOfEmployment, incomeLimit),
      applicationRequirement: applicationRequirementDecision(pr, programRequirementsDecisions),
      requirementTypeId: applicationRequirementDecision(pr, programRequirementsDecisions)?.requirementTypeId,
      requirementTypeName: applicationRequirementDecision(pr, programRequirementsDecisions)?.requirementTypeName,
      requirementStatusId: applicationRequirementDecision(pr, programRequirementsDecisions)?.requirementStatusId,
      requirementStatusName: applicationRequirementDecision(pr, programRequirementsDecisions)?.requirementStatusName,
    }

    // handles when the application does not have a requirement decision, sets default
    if (pr.name?.trim()?.toLowerCase() !== 'employment' && !programRequirementInformation[pr.name]?.applicationRequirementDecisionId && programRequirementInformation[pr.name]?.programRequirementLimit !== 'N/A') {
      programRequirementInformation[pr.name].requirementTypeId = getRequirementType(pr)?.id;
      programRequirementInformation[pr.name].requirementTypeName = getRequirementType(pr)?.name;
      programRequirementInformation[pr.name].requirementStatusId = defaultApplicationRequirementDecision?.id;
      programRequirementInformation[pr.name].requirementStatusName = defaultApplicationRequirementDecision?.name;
    }
  });

  return programRequirementInformation;

}
async function verify(incomeProgramRequirements, programRequirementInformation) {
  const verificationResults = [
    { requirement: "DTI", status: "incomplete" },
    { requirement: "Employment", status: "incomplete" },
    { requirement: "Income", status: "incomplete" }
  ];

  incomeProgramRequirements?.forEach((r) => {
    const notRequired = 
    (programRequirementInformation['Income'].programRequirementLimit === 'N/A' &&
    programRequirementInformation['Employment'].programRequirementLimit === 'N/A' &&
    programRequirementInformation['DTI'].programRequirementLimit === 'N/A');
    
    if (
      // all requirements are N/A
      notRequired 
      // record is not employment AND limit is NOT N/A and status is Passed
      || (programRequirementInformation[r.name].programRequirementTypeName !== 'Employment' && programRequirementInformation[r.name].programRequirementLimit !== 'N/A' && (programRequirementInformation[r.name].requirementStatusName === 'Passed' || programRequirementInformation[r.name].requirementStatusName == "Passed - CU Override"))
      // DTI not required
      || (programRequirementInformation[r.name].programRequirementTypeName === 'DTI' && programRequirementInformation[r.name].programRequirementLimit === 'N/A' && programRequirementInformation[r.name].requirementStatusName === undefined)
      // Employment is not required to be passed regardless of the limit
      || (programRequirementInformation[r.name].programRequirementTypeName === 'Employment' && programRequirementInformation[r.name].programRequirementLimit !== 'N/A' && programRequirementInformation[r.name].requirementStatusName === undefined)
      // Income not required
      || (programRequirementInformation[r.name].programRequirementTypeName === 'Income' && programRequirementInformation[r.name].programRequirementLimit === 'N/A') 
      ) {
      const result = verificationResults?.find((v: any) => v.requirement === r.name);
      if (result) {
        result.status = "complete";
      }
    }
    // handle failed
    else if (programRequirementInformation[r.name].requirementStatusName === 'Failed') {
      const result = verificationResults?.find((v: any) => v.requirement === r.name);
      if (result) {
        result.status = "failed";
      }
    }
    else {
      return;
    }
  });

  const complete = verificationResults.every((incomeRequirement) => incomeRequirement.status === "complete");
  const failedRequirements = verificationResults?.filter((r) => r.status === 'failed');
  const openRequirements = verificationResults?.filter((r) => r.status === 'incomplete');
  let result = "incomplete";

  if (complete === true) {
    result = "complete";
  } else {
    if (failedRequirements?.length > 0 || openRequirements?.length > 0 && failedRequirements?.length > 0) {
      // set the income tab to failed
      result = "failed";
    }

    if (openRequirements?.length > 0 && failedRequirements?.length === 0) {
      result = "incomplete";
    }
  }
  return result;
}

  return {
    initializeIncomeTab
  }
}