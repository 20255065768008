export interface LOSType {
	abbreviation?: string;
	createdAtUtc: Date;
	createdBy: string;
	description: string;
	id: number;
	name: string;
	programTypeId?: number,
	updatedAtUtc?: Date;
	updatedBy?: string;
}

export interface AppData {
	application: Application;
	applicants: Applicant[];
	authorizedThirdParty: AuthorizedThirdParty;
}

export interface Application {
	id: number;
	referenceId?: string;
	leadId?: number;
	lenderId?: number;
	applicationStatusId: number;
	applicationStatusName?: string;
	productTypeId: number;
	productTypeName?: string;
	loanAmount?: number;
	isCompliantAppAtUtc?: Date;
	metadata?: any;
	schoolConfiguration?: SchoolConfiguration;
	ratePlans?: RatePlan[];
	loanConfiguration?: LoanConfig;
	submittedAtUtc?: Date;
	loanId?: number;
	applicationCredits?: any;
	statusReasonId?: number;
	statusReasonName?: string;
	// TEMPORARY
	[key: string]: any;
}

export interface SchoolConfiguration {
	applicationId: number;
	schoolId?: number;
	schoolName?: string;
	schoolLevelTypeId?: number;
	schoolLevelTypeName?: string;
	degreeProgram?: string;
	major?: string;
	currentGPA?: string;
	graduationDate?: Date;
	schoolUndecided: boolean;
	fullTime: boolean;
	academicTerm?: string;
}

export interface LoanConfig {
	applicationId: number;
	ratePlanId: number;
	selectedByApplicantId: number;
	repaymentTypeId: number;
	repaymentTypeName?: string;
}

export interface RatePlan {
	id: number;
	applicationId: number;
	loanProgramId: number;
	pricingTierId: number;
	rateTypeId: number;
	rateTypeName?: string;
	repaymentTerm: number;
	rate?: number;
	rateOverriden?: boolean;
	rateOverrideAt?: Date;
	apr?: number;
}

export interface RatePlan {
	id: number;
	applicationId: number;
	loanProgramId: number;
	pricingTierId: number;
	rateTypeId: number;
	rateTypeName?: string;
	repaymentTerm: number;
	rate?: number;
	rateOverriden?: boolean;
	rateOverrideAt?: Date;
	apr?: number;
}
export interface Applicant {
	id: number;
	applicationId: number;
	userId?: string;
	applicantTypeId: number;
	applicantTypeName?: string;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	suffixTypeId?: number;
	suffixTypeName?: string;
	dateOfBirth?: Date;
	citizenshipStatusId?: number;
	citizenshipStatusName?: string;
	userAgent?: string;
	ipAddress?: string;
	submittedAt?: Date;
	ssn?: string;
	isMLACovered?: boolean;
	mlaResponseAtUtc?: Date;
	contactInfo?: ContactInfo;
	membership?: Membership;
	concierge?: Concierge;
	addresses?: Address[];
	incomes?: Income[];
	employments?: Employment[];
	surveyResponses?: SurveyResponse[];
	// additional fields added by FE
	fullName?: string;
	initials?: string;
	totalIncome?: any;
	dti?: DebtToIncome[];
}

export interface DebtToIncome {
	id: number;
	dtiPercentage: number;
	calculatedDTI: number;
	dtiStatusId?: number;
	dtiTypeId?: number;
	verified: boolean;
}
export interface AuthorizedThirdParty {
	id: number;
	applicationId: number;
	firstName?: string;
	lastName?: string;
	phoneNumber?: string;
	phoneNumberTypeId?: number;
	phoneNumberTypeName?: string;
	emailAddress?: string;
	dateOfBirth?: Date | string; // formatted to a string on FE
	sourceTypeId: number;
	sourceTypeName?: string;
	authorizedByApplicantId: number;
	addedByUserId: string;
	address?: Address;
	// additional fields added by FE
	applicantTypeName?: string;
	fullName?: string;
	initials?: string;
}

export interface Concierge {
	applicantId: number;
	conciergeConsent: boolean;
	preferredContactTypeId: number;
	preferredContactTypeName: string;
}

export interface ContactInfo {
	applicantId: number;
	emailAddress?: string;
	phoneNumber?: string;
	phoneNumberTypeId?: number;
	preferredContactTypeId?: number;
	preferredContactTypeName?: string;
	// additional fields added by FE
	phoneDisplay?: string;
}

export interface Employment {
	id: number;
	applicantId: number;
	employerName: string;
	employmentTypeId?: number;
	employmentTypeName?: string;
	startedAt?: Date;
	endedAt?: Date;
}

export interface Membership {
	applicantId: number;
	isMember: boolean;
	memberId?: string;
	membershipStatusId: number;
	membershipStatusName?: string;
	statusChangedAtUtc?: Date;
	isJointOnStudentAccount?: boolean;
	[key: string]: any;
}

export interface Income {
	id: number;
	applicantId: number;
	incomeTypeId: number;
	incomeTypeName?: string;
	incomeAmount: number;
	incomePeriodTypeId: number;
	incomePeriodTypeName?: string;
	isVerified: boolean;
	documentId: number;
	incomeDate: Date;
	incomeStatusTypeId: number;
	incomeStatusTypeName?: number;
	applicantEmploymentId?: number;
	applicantEmploymentName?: string;
	employmentStartDate?: Date;
	employmentEndDate?: Date;
	// temporary until we finalize additional fields added by FE
	[key: string]: any;
}

export interface SurveyResponse {
	applicantId: number;
	questionId: number;
	response: string;
}

export interface Address {
	id: number;
	applicantId: number;
	addressTypeId: number;
	addressTypeName?: string;
	addressUnitTypeName?: string;
	addressUnitTypeId?: number;
	address1?: string;
	address2?: string;
	city?: string;
	stateId: number;
	stateName?: string;
	countryId: number;
	countryName?: string;
	postalCode?: string;
}
export interface addOrUpdateJudgementDenials {
	applicationId: number;
	judgmentTypeIds: number[];
	userId?: string;
	createdBy?: string;
}
export interface JudgmentalDenialResponse {
	applicationId: number;
	judgmentDenialTypes: JudgmentalDenial[];
}

export interface JudgmentalDenial {
	applicationJudgmentDenialId: number;
	judgmentDenialTypeId: number;
	judgmentDenialTypeName: string;
}

export interface Stipulation {
	id: number;
	applicationId: number;
	applicantId: number;
	applicantDocumentId?: number;
	docTypeId?: number;
	documentId?: number;
	stipulationCategoryId: number;
	stipulationCategoryName: string;
	stipulationTypeId: number;
	stipulationTypeName: string;
	stipulationText: string;
	stipulationStatusId: number;
	stipulationStatusName: string;
	stipulationStatusReasonId: number;
	stipulationStatusReasonName: string;
	statusChangedBy?: string;
	isInternal: boolean;
	isExpectedDocument: boolean;
	userId?: string;
	// FE added temporary
	[key: string]: any;
}

export interface IncomeDocument {
	// temporary until we finalize additional fields added by FE
	[key: string]: any;
}

export interface LOSApplication {
	applicants: Applicant[];
	commentData: Comment[];
	canEdit: boolean;
	creditData: any;
	creditReport: object;
	currentTab: string;
	data: Application;
	debtData: any;
	documentsData: Array<LOSDocument>;
	documents: Array<Document>;
	documentsUploadDisplay: boolean;
	focusComments: boolean;
	formDisabled: boolean;
	formSaved: boolean;
	identityDocuments: any;
	identitySelected: any;
	incomeData: any;
	loading: boolean;
	loanConfigData: any;
	mobileHidden: boolean;
	newComment: string;
	notifications: any;
	programLimits: any;
	ratePlanData: any;
	showComments: boolean;
	showDetails: boolean;
	showOwnerDropdown: boolean;
	statusComment: string;
	statusDropdown: boolean;
	statusSelected: boolean;
	stipulationsData: any[];
	tabs: any;
	tabForms?: any;
}

export interface LOSDocument {
	applicantDocumentId: number;
	documentId: number;
	name: string;
	applicantId: number;
	createdAtUtc: Date;
	documentTypeId: number;
	documentStatusTypeId: number;
	documentStatusTypeName: string;
	documentStatusReasonId?: number;
	documentStatusReasonName?: string;
	documentYear?: Date;
	documentType: DocumentType;
	fileExtension: string;
	uploadedDocumentMetadata: any;
	// temporary until we finalize additional fields added by FE
	[key: string]: any;
}

export interface Document {
	applicantDocumentId: number;
	applicantId: number;
	createdAtUtc: Date;
	documentId: number;
	documentStatusReasonId?: number;
	documentStatusReasonName?: string;
	documentStatusTypeId: number;
	documentStatusTypeName: string;
	documentType: DocumentType;
	documentTypeId: number;
	documentYear?: Date;
	fileExtension: string;
	name: string;
	uploadedDocumentMetadata: any;
	// temporary until we finalize additional viewmodel fields added by FE
	[key: string]: any;
}

export interface DocumentType {
	description?: string;
	documentTypeID: number;
	name: string;
}
export interface Comment {
	id: number;
	comment: string;
	applicationId: number;
	lastChangedAt: Date;
	userDisplayName: string;
	userId: string;
}

export interface CreditData {
	applicantCredits: Credit[];
	applicationId: number;
	creditDecisionId: number;
	creditDecisionStatusId: number;
	creditDecisionStatusTypeName: string;
	id: number;
}

export interface Credit {
	applicantId: number;
	applicationCreditId: number;
	creditId: number;
	creditStatusId: number;
	creditStatusTypeName: string;
	id: number;
}

export interface ProgramLimit {
	effectiveDate: Date;
	expirationDate?: Date;
	limitTypeId: number;
	limitTypeName?: string;
	programId: number;
	value: any;
}

export interface AvailableToDrawAmount {
	availableToDrawAmount: number;
	drawsEnabled: boolean;
	drawEnableOverrideUntilUtc?: Date | null;
	drawOverrideAmount?: number | null;
	locStatusType?: number | null;
}

export interface CreateDrawRequest {
	loanId: number;
	enrollmentTermId: number;
	schoolLevelTypeId: number;
	schoolCode?: string | null;
	schoolBranch?: string | null;
	anticipatedGraduationDate?: Date | null;
	requestedAmount: number;
}

export interface Draw {
	id: number;
	loanId: number;
	lenderId?: number | null;
	drawStatusTypeId?: number | null;
	schoolLevelTypeId?: number;
	schoolCode: string | null;
	schoolBranch: string;
	enrollmentTermId: number;
	anticipatedGraduationDate?: Date;
	category?: string | null;
	requestedAmount: string;
	sequenceNumber?: string | null;
	cluid?: string | null;
	loanPeriodStartDate?: Date;
	loanPeriodEndDate?: Date;
	certifiedAmount?: number | null;
	schoolCertificationDate?: Date;
	enrollmentStatus?: number | null;
	enrollmentTerm: EnrollmentTermModel;
	drawStatusType: DrawStatusType;
	disbursements: Disbursement[];
	certificationMethodId?: number;
}

export interface DrawStatusType {
	id: number;
	name: string;
}

export interface UpdateDrawRequest {
	id: number;
	enrollmentTermId?: number | null;
	certificationMethodId?: number | null;
	schoolLevelTypeId?: number | null;
	schoolCode?: string | null;
	schoolBranch?: string | null;
	anticipatedGraduationDate?: Date | null;
	category?: string | null;
	loanPeriodStartDate?: Date | null;
	loanPeriodEndDate?: Date | null;
	cluid?: string | null;
	schoolCertificationDate?: Date | null;
}

export interface UpdateDrawRequestedAmountRequest {
	id: number;
	requestedAmount: number;
}

export interface UpdateDrawStatusesRequest {
	drawIds: number[];
	drawStatusTypeId: number;
}

export interface Disbursement {
	id: number;
	disbursedAmount?: number;
	transactionDate?: Date | null;
	disbursementStatusTypeId?: number | null;
	disbursementStatusType?: LoanServiceType | null;
	drawDisbursementStatusReasonTypeId?: number | null;
	certifiedAmount?: number;
	sequenceNumber?: string;
}

export interface EnrollmentTermModel {
	id: number;
	name?: string | null;
	description?: string | null;
	startDate?: Date | null;
	endDate?: Date | null;
	visibleStartDate?: Date | null;
	visibleEndDate?: Date | null;
}

export interface LoanServiceType {
	id?: number;
	name?: string;
	description?: string;
}
export interface DrawDisbursementStatusReasonTypeId {
	id?: number;
	name?: string;
}


export interface LinkDocumentToStipulationRequest {
	applicantDocumentId: number;
	changeStatusCurrentlyLinkedApprovedPendingDocument?: boolean;
	changeStatusCurrentlyLinkedApprovedPendingDocumentTo?: number;
	userId?: string;
}

export interface AddStipulationRequest {
	stipulationTypeId: number;
	overrideText?: string;
	addedByUserId?: string;
}

export interface Section {
	title: string;
	name: string;
	collapsible: boolean;
	collapsed: boolean;
	items: Array<Stipulation>;
}

export enum PdfPageRotateAngle {
	RotateAngle0 = 0,
	RotateAngle90,
	RotateAngle180,
	RotateAngle270,
}

export interface DocumentRotateOptions {
	pageIndices: number[];
	rotationAngle: PdfPageRotateAngle;
}

export interface DocumentRotateRequest {
	documentId: number;
	options: DocumentRotateOptions[];
}

export interface DocumentSplitOptions {
	startIndex: number;
	endIndex: number;
	documentType: number;
	applicantViewable?: boolean | null;
	rotationAngle?: PdfPageRotateAngle | null;
}

export interface DocumentSplitRequest {
	documentId: number;
	options: DocumentSplitOptions[];
}

export interface DocumentSplitResponse {
	documentIds: number[];
	requestedCount: number;
	successCount: number;
	isSuccess: boolean;
}

export interface MergeDocumentsRequest {
	documentIds: number[];
	documentType: number;
	applicationId: number;
	applicantId: number;
	applicantViewable?: boolean | null;
}

export interface FinalReviewApplication {
	data: {
		id: number;
		lenderId: number;
		programTypeId: number;
		productTypeId: number;
	};
	commentData: [];
	tags: [FinalReviewTag] | null;
}

export interface FinalReviewTag {
	id: number;
	tagTypeId: number;
	tagTypeName: string;
	tagStatusId: number;
	tagStatusName: string;
}

export enum TagStatus {
	Open = 1,
	Reviewed = 2,
	Closed = 3,
	Removed = 4,
	Priority = 5
}

export enum FinalReviewType {
	ManagerialReview = 1,
	FinalReview = 2
}