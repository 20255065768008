<template>
  <searchpage pageName="Loans" :filters="filters"></searchpage>
</template>

<script setup>
  import searchpage from "@/components/layout/SearchPage.vue";

  var filters = [
    {
      description: "Status",
      param: "StatusIds",
      results: [
        {
          name: "Active",
          id: 1,
          checked: false,
          param: "StatusIds",
        },
        {
          name: "Inactive",
          id: 2,
          checked: false,
          param: "StatusIds",
        },
      ],
    },
  ];
</script>
