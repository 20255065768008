import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Applicant } from "@/models/opal";

export interface ApplicantState {
  primary: Applicant;
  secondary: Applicant;
  backup: any;
  authorizedThirdParty: any;
  selected: string;
}

export const useApplicantsStore = defineStore('applicants', () => {
  const applicants = ref<ApplicantState>({
    primary: {},
    secondary: {},
    backup: {},
    authorizedThirdParty: {
      id: 0,
      sourceTypeId: 1,
      address: {
        applicantId: 0,
        id: 0,
        addressTypeId: 1,
        address1: null,
        address2: null,
        city: null,
        stateId: null,
        postalCode: undefined,
        countryId: null,
      },
      authorizedByApplicantId: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      phoneNumber: undefined,
      phoneNumberTypeId: null,
      dateOfBirth: null,
    },
    selected: "primary",
  } as ApplicantState);

  return { applicants }
})