import { defineStore, storeToRefs } from "pinia";
import { ref, computed } from "vue";
import {
	IncomeDocument,
	Applicant,
	LOSDocument,
	Income,
	AuthorizedThirdParty,
} from "@/models/opal";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore";
import { useApplicantsStore } from "@/store/store/applicantsStore";
import { FormSchema, FieldSchema, Option } from "@/models/form";
import vuexStore from "@/store";
import useFunctions from "@/use/functions1";
import useFormatter from "@/use/formatter";

export const useIncomeTabStore = defineStore("incomeTab", () => {
	const { sortLosTypesByDescription } = useFunctions();
	const { formatMoney } = useFormatter();

	const incomeDocuments = ref<Array<IncomeDocument>>([]);
	const { documents } = storeToRefs(useDocumentsTabStore());
	const { applicants } = storeToRefs(useApplicantsStore());

	const selectedApplicant = computed<Applicant | AuthorizedThirdParty>(
		() => applicants.value[applicants.value.selected]
	);
	const selectedApplicantIncomes = computed<Array<Income>>(
		() => applicants.value[applicants.value.selected].incomes || []
	);
	const currentlyEditingIncomeIds = ref<Array<number>>([] as number[]);
	const currentlyViewingDetailIds = ref<Array<number>>([] as number[]);

	const sortedIncomeYears = sortLosTypesByDescription(
		vuexStore?.state?.types["IncomeYears"]?.results
	);
	const incomeYearOptions = sortedIncomeYears?.map((o: any) => {
		return {
			label: o.description,
			value: o.id,
		} as Option;
	});

	const sortedIncomeTypes = sortLosTypesByDescription(
		vuexStore?.state.types["Income"]?.results
	);
	const incomeTypeOptions = sortedIncomeTypes?.map((o: any) => {
		return {
			label: o.description,
			value: o.id,
		} as Option;
	});
	const sortedIncomePeriodTypes = sortLosTypesByDescription(
		vuexStore?.state.types["IncomePeriods"]?.results
	);
	const incomePeriodTypeOptions = sortedIncomePeriodTypes?.map((o: any) => {
		return {
			label: o.description || o.label,
			value: o.id || o.value,
		} as Option;
	});

	const schema = ref({
		incomeYearTypeId: {
			name: "incomeYearTypeId",
			type: "select",
			hideLabel: true,
			hideErrorMessage: true,
			required: true,
		} as FieldSchema,
		applicantEmploymentName: {
			name: "applicantEmploymentName",
			type: "text",
			hideLabel: true,
			hideErrorMessage: true,
		} as FieldSchema,
		applicantEmploymentTypeId: {
			name: "applicantEmploymentTypeId",
			type: "select",
			hideLabel: true,
			hideErrorMessage: true,
		} as FieldSchema,
		incomeTypeId: {
			name: "incomeType",
			type: "select",
			hideLabel: true,
			required: true,
			hideErrorMessage: true,
		} as FieldSchema,
		incomeAmount: {
			name: "incomeAmount",
			type: "text",
			hideLabel: true,
			required: true,
			hideErrorMessage: true,
		} as FieldSchema,
		incomePeriodTypeId: {
			name: "incomePeriodTypeId",
			type: "select",
			hideLabel: true,
			required: true,
			hideErrorMessage: true,
		} as FieldSchema,
		incomeStatusTypeId: {
			name: "incomeStatus",
			type: "select",
			hideLabel: true,
			// required: true,
			hideErrorMessage: true,
		} as FieldSchema,
	} as FormSchema);

	function getSchemas() {
		const schemas = {} as any;
		incomeDocuments.value?.forEach((incomeDocument: IncomeDocument) => {
			incomeDocument?.incomes?.forEach((income: Income) => {
				schemas[income.id] = schema;
			});
		});

		return schemas;
	}
	function getIncomeDocumentsByApplicant() {
		// reset income docs
		incomeDocuments.value = [];

		const uploadedDocuments = documents.value?.filter(
			(d: LOSDocument) =>
				d.documentStatusTypeName.trim() !== "Generated" &&
				d.applicantId === selectedApplicant.value?.id
		);
		for (const doc of uploadedDocuments) {
			const document = {
				...doc,
				documentName: doc?.uploadedDocumentMetadata?.originalFileName
					? doc.uploadedDocumentMetadata.originalFileName
					: "Unknown",
				year: doc.documentYear
					? new Date(doc.documentYear).getFullYear()
					: null,
				formDisabled: true,
				editStatus: false,
				editYear: false,
				editType: false,
				showDocument: false,
				showStatusReasonSection: false,
				showStatusReasonCommentArea: false,
				incomeTabVisible: doc.documentCategories.find(
					(dc: any) => dc.categoryName === "Income"
				)
					? true
					: false,
				comment: "",
				incomes: [],
			} as IncomeDocument;

			if (document.incomeTabVisible) {
				const index = incomeDocuments.value.findIndex(
					(item: IncomeDocument) =>
						item.applicantDocumentId === document.applicantDocumentId
				);

				if (index !== -1) {
					incomeDocuments.value[index] = document;
				} else {
					incomeDocuments.value?.push(document);
				}
			}
		}

		// Link incomes to each income doc if income docs exist
		if (incomeDocuments.value?.length > 0) {
			if (
				selectedApplicantIncomes.value &&
				selectedApplicantIncomes.value?.length > 0
			) {
				for (const incomeDocument of incomeDocuments.value) {
					for (const income of selectedApplicantIncomes.value) {
						if (
							income.applicantDocumentId === incomeDocument.applicantDocumentId
						) {
							incomeDocument.incomes.push(income);
							// incomeDocument.incomeFormSchemas[income.id] = schema
						}
					}
				}
			}
		}
	}

	function updateIncomeDocument(
		applicantDocumentId: number,
		updatedIncomeDocument: IncomeDocument
	) {
		const index = incomeDocuments.value.findIndex(
			(item: IncomeDocument) => item.applicantDocumentId === applicantDocumentId
		);

		if (index !== -1) {
			incomeDocuments.value[index] = updatedIncomeDocument;
		}
	}

	function toggleIncomeDocumentPdfViewer(incomeDocument: IncomeDocument) {
		const incomeDocumentIndex = incomeDocuments.value.findIndex(
			(item: IncomeDocument) =>
				item.applicantDocumentId === incomeDocument.applicantDocumentId
		);
		if (incomeDocumentIndex !== -1) {
			incomeDocuments.value[incomeDocumentIndex].showDocument =
				!incomeDocuments.value[incomeDocumentIndex].showDocument;
		}
	}

	function toggleEdit(incomeDocument: IncomeDocument, income: Income) {
		console.log("toggleEdit() ", {
			income: income,
			incomeDocument: incomeDocument,
		});
		// add to edit array
		const incomeId = income.id || income.incomeId;
		const index = currentlyEditingIncomeIds.value.findIndex(
			(id) => id === incomeId
		);

		if (index === -1) {
			currentlyEditingIncomeIds.value.push(incomeId);
		}
	}

	function isEditing(income: Income) {
		const incomeId = income?.id || income?.incomeId;
		return currentlyEditingIncomeIds.value?.includes(incomeId) || false;
	}

	function isIncomeDetailShowing(incomeDocument: IncomeDocument) {
		const applicantDocumentId = incomeDocument.applicantDocumentId;
		return currentlyViewingDetailIds.value?.includes(applicantDocumentId);
	}

	function untoggleEdit(income: Income) {
		const incomeId = income.id || income.incomeId;
		const index = currentlyEditingIncomeIds.value.findIndex(
			(id) => id === incomeId
		);

		if (index !== -1) {
			currentlyEditingIncomeIds.value.splice(index, 1);
		}
	}

	function cancel(income: Income) {
		const incomeId = income.id || income.incomeId;
		const index = currentlyEditingIncomeIds.value.findIndex(
			(id) => id === incomeId
		);

		if (index === -1) {
			currentlyEditingIncomeIds.value.push(incomeId);
		}
	}

	function calculateIncomeTotal(income: any, incomePeriodTypeId: number) {
		if (income && incomePeriodTypeId) {
			const incomePeriodTypeName = incomePeriodTypeOptions.find(
				(o: any) => o.value === parseInt(income.incomePeriodTypeId)
			)?.label;
			const incomeTypes = {
				Annual: 1,
				Monthly: 12,
				"Bi-Weekly": 26,
				Weekly: 52,
				Bonus: 1,
				"Semi-Monthly": 24,
				Quarterly: 4,
			};
			// calculate the total income based on the income type provided
			income.incomeTotal =
				parseFloat(income.incomeAmount) * incomeTypes[incomePeriodTypeName];
			return formatMoney(income.incomeTotal);
		}
	}

	function toggleDetails(incomeDocument: IncomeDocument) {
		// add to array if not present
		const index = currentlyViewingDetailIds.value.findIndex(
			(id) => id === incomeDocument.applicantDocumentId
		);

		if (index === -1) {
			currentlyViewingDetailIds.value.push(incomeDocument.applicantDocumentId);
		} else {
			currentlyViewingDetailIds.value.splice(index, 1);
		}
	}

	function discardChanges(
		incomeDocument: IncomeDocument,
		originalIncome: Income
	) {
		const incomeId = originalIncome.id || originalIncome.incomeId;

		const incomeDocumentIndex = incomeDocuments.value.findIndex(
			(item: IncomeDocument) =>
				item.applicantDocumentId === incomeDocument.applicantDocumentId
		);
		const incomeDocumentToEdit = incomeDocuments.value[incomeDocumentIndex];
		const index = incomeDocumentToEdit.incomes.findIndex(
			(income: Income) => incomeId === income.id
		);

		if (index !== -1) {
			incomeDocumentToEdit.incomes[index] = originalIncome;
		}
	}
	return {
		incomeDocuments,
		updateIncomeDocument,
		selectedApplicant,
		getIncomeDocumentsByApplicant,
		toggleEdit,
		toggleIncomeDocumentPdfViewer,
		currentlyEditingIncomeIds,
		untoggleEdit,
		isEditing,
		isIncomeDetailShowing,
		toggleDetails,
		cancel,
		getSchemas,
		calculateIncomeTotal,
		discardChanges,
	};
});
