import { Module, ActionContext } from 'vuex';
import axios from 'axios';

interface DocumentTabState {
    documents: any[];
    currentDocument: any;
    newDocument: any;
}

const documentTabModule: Module<DocumentTabState, any> = {
    namespaced: true,
    state: {
        documents: [],
        currentDocument: {},
        newDocument: {}
    },
    getters: {
        documents: (state: DocumentTabState): any => {
            return state.documents;
        },
        currentDocument: (state: DocumentTabState): any => {
            return state.currentDocument;
        },
        newDocument: (state: DocumentTabState): any => {
            return state.newDocument;
        },
        getSelectedTypeName: (state: DocumentTabState) => (documentId: any) => {
            return state.currentDocument.selectedTypes[documentId];
        }
    },
    mutations: {
        setDocuments(state: DocumentTabState, documents: any) {
            state.documents = documents;
        },
        setCurrentDocument(state: DocumentTabState, document: any) {
            state.currentDocument = document;
        },
        SET_NEW_DOCUMENT(state: DocumentTabState, applicantId: any) {
            state.newDocument = {};
        },
        discardChanges(state: DocumentTabState) {
            state.newDocument = {};
        }, 
        setSelectedTypeName(state: DocumentTabState, payload: { documentId: any, selectedTypeName: string }) {
            state.currentDocument.selectedTypes[payload.documentId] = payload.selectedTypeName;
        }
    },
    actions: {
        updateSelectedTypeName({ commit }: ActionContext<DocumentTabState, any>, payload: { documentId: any, selectedTypeName: string }) {
            commit('setSelectedTypeName', payload);
            
            // if (selectedTypeName) {
            //     console.log(`selectedTypeName is: ${selectedTypeName}`);
            // } else {
            //     console.log('selectedTypeName is not available');
            // }
        },

    }
};

export default documentTabModule;