import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { ApplicationProgramRequirement } from "@/models/programRequirements"

export const useProgramRequirementsStore = defineStore('programRequirements', () => {
const programRequirementsDecisions = ref<Array<ApplicationProgramRequirement>>([]);

const identityTabCheckmarkStatus = computed(() => identityProgramRequirementDecision?.value?.length > 0 ? verifyIdentityCheckmark() : "incomplete");
const identityProgramRequirementDecision = computed(() => programRequirementsDecisions.value?.filter((pr: ApplicationProgramRequirement) => pr.requirementTypeName?.trim()?.toLowerCase() === 'identity'));

function updateProgramRequirement(requirementTypeName, updatedProgramRequirementDecision) {
  const index = programRequirementsDecisions.value.findIndex((item: ApplicationProgramRequirement) => item?.requirementTypeName === requirementTypeName);

  if (index !== -1) {
    programRequirementsDecisions.value[index] = updatedProgramRequirementDecision;
  }
}

function verifyIdentityCheckmark() {
  let result = "";
  const decision = identityProgramRequirementDecision.value[0];
  if ((decision.requirementStatusName === 'Passed' || decision.requirementStatusName == "Passed - CU Override")) {
    result = "complete";
  }
  else if (decision.requirementStatusName === 'Failed') {
    result = "failed";
  }
  else {
    result = "incomplete";
  }

  return result
}

  return { programRequirementsDecisions, identityProgramRequirementDecision, updateProgramRequirement, identityTabCheckmarkStatus }
});