export default function usePaymentOptions(): any {
  function getPaymentOptionHeader(type: number | string): string {
    switch (type) {
      case "Flat":
        return "";
      case "Deferred":
        return "Defer Payments Until After School";
      case "Interest":
        return "Interest Only During School";
      case "Regular":
        return "Immediately Start Payments";
      default:
        return "";
    }
  }
  
  function repaymentTermEnum(repaymentTermId: number): number {
    switch (repaymentTermId) {
      case 1:
        return 5;
      case 2:
        return 7;
      case 3:
        return 10;
      case 4:
        return 15;
      case 5:
        return 20;
      case 6:
        return 25;
      default:
        return 0;
    }
  }
 
  function getRateOptionHeader(rateTypeId: number): string {
    switch (rateTypeId) {
      case 1:
        return "Fixed Interest Rate";
      case 2:
        return "Variable Interest Rate";
      default:
        return "";
    }
  }

  return {
    getPaymentOptionHeader,
    repaymentTermEnum,
    getRateOptionHeader,
  };
}
