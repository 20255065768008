<template>
	<div class="xmodal-animate" v-auto-animate="$animate">
		<div
			class="xmodal-wrapper"
			v-if="modalActive"
			@click.self="modalActive = false">
			<div class="xmodal-inner" :data-fullscreen="store.state.modal.fullscreen">
				<div class="xmodal">
					<div class="xmodal-close" @click="modalActive = false"></div>
					<h2 v-if="store.state.modal.title" class="xmodal-title">
						{{ store.state.modal.title }}
					</h2>
					<p v-if="store.state.modal.description" class="xmodal-description">
						{{ store.state.modal.description }}
					</p>
					<div
						v-if="store.state.modal.html"
						v-html="store.state.modal.html"
						class="xmodal-html"></div>
					<div
						v-if="store.state.modal.buttons?.length > 0 && !store.state.modal.loading" class="xmodal-buttons">
						<button
							v-for="(button, index) in store.state.modal.buttons"
							:key="index"
							@click="button.onClick()">
							{{ button.title }}
						</button>
					</div>
					<div v-else-if="store.state.modal.buttons?.length > 0 && store.state.modal.loading === true" class="xmodal-buttons">	<opal-button :text="store.state.modal.title" :loading="true" /></div>
					<div v-else class="xmodal-buttons">
						<button @click="modalActive = false">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import store from '@/store';
import { watch } from '@vue/runtime-core';
import OpalButton from '@/components/shared/OpalButton.vue';
var modalActive = $ref(false);

	watch(
		() => store.state.modal.active,
		(value) => {
			modalActive = value;

			// deep allows the watch() to fire on all nested mutations
		},
		{ deep: true }
	);
</script>

<style scoped></style>
