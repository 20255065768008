import { defineStore } from "pinia";
import { ApplicantEvaluations } from "@/types/evaluation";
import axios from "axios";

export const useEvaluationsTabStore = defineStore({ 
    id: "evaluationsTab", 
    state: () => ({
        applicantEvaluations: [] as ApplicantEvaluations[],
        selectedEvaluation: null,
        loading: false,
        nois: ['ADDMM', 'SECFREEZE', 'SSN12', 'SSNMM']
    }),
    
    getters: {
        evaluations(state) {
            return state.applicantEvaluations;
        },
        isDataLoaded(state){
            return state.loading;
        },
        hasNOI(state)
        {
            const creditAlerts = state.applicantEvaluations.flatMap(item => 
                item.creditEvaluation?.creditAlerts?.split(",")
                .map(alert => alert.trim()));

        
            if(creditAlerts === undefined || creditAlerts.length == 0)
                return false;

            return  creditAlerts.some(alert => state.nois.includes(alert || ""));
        }
    },
    actions: {
       async getEvaluationDetails(referenceId: string){
            try {
                const response = await axios.get(`/api/applications/${referenceId}/EvaluationDetails`);
                if (Object.keys(response.data).includes("applicantEvaluations")) {
                    this.applicantEvaluations  = response.data.applicantEvaluations;
                    this.loading = true;
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    });
   