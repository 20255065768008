export interface User {
	id: string;
}

export interface Role {
	id: number;
	name: string | null;
	rolePermissions: RolePermission[];
}

export interface RolePermission {
	roleId: Roles;
	roleName: string | null;
	permissionId: Permissions;
	permissionName: string | null;
	permissionLevel: PermissionLevels;
	isDeleted: boolean;
}

export enum Roles {
	AgentI = 1,
	AgentII = 2,
	ClientI = 3,
	ClientII = 4,
	ClientIII = 5,
	DocumentProcessorI = 6,
	Accounting = 7,
	StrategicPartners = 8,
	BusinessDevelopment = 9,
	Compliance = 10,
	Collections = 11,
	LoanProcessorI = 12,
	LoanProcessorII = 13,
	CreditAnalyst = 14,
	OperationsI = 15,
	OperationsII = 16,
	Admin = 17,
	CSR = 18,
}

export enum Permissions {
	ProgramConfiguration = 1,
	LenderConfiguration = 2,
	UserManagement = 3,
	Reports = 4,
	DocumentProcessing = 5,
	ProgramRequirements = 6,
	JournalComments = 7,
	Orchestration = 8,
	ApplicantDemographics = 9,
	ApplicantSSN = 10,
	Overrides = 11,
	Evaluation = 12,
	CancelHoldRelease = 13,
	LoanDetails = 14,
	Draws = 15,
	Certifications = 16,
	Disbursements = 17,
	AnnualReview = 18,
	EnrollmentHistory = 19,
	SchoolDetails = 20,
	ProofOfMembership = 21,
	AuthorizedThirdParty = 22,
	Income = 23,
	Identity = 24,
	Esign = 25,
	Pricing = 26,
	Credit = 27,
	LexisNexis = 28,
	AnalystReview = 29,
	DisbursementBatches = 30,
	FinalReview = 31,
	Stipulations = 32,
	DocumentStatus = 33
}

/**
 * PermissionLevel is a flags enum.
 * Use bitwise operations to combine.
 */
export enum PermissionLevels {
	None = 0,
	Read = 1,
	Create = 2,
	Update = 4,
	Delete = 8,
}

export * from "./permissions";
