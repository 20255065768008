<template>
  <Loader :stretch="true" :inverse="true"></Loader>
</template>

<script>
  import { defineComponent } from "vue";
  import { mapActions } from "vuex";
  import { usePermissionsStore } from "@/store/store/permissionsStore";
  import Loader from "../components/Loader.vue";
  import { $api } from "@/services/api1";

  export default defineComponent({
    name: "OidcCallback",
    components: { Loader },
    methods: {
      ...mapActions("oidcStore", ["oidcSignInCallback"]),
      async getAndSetUserRoles() {
        const permissionsStore = usePermissionsStore();
         await permissionsStore.getUserRoles();
      },
    },
    async mounted() {
      this.oidcSignInCallback()
        .then(async (redirectPath) => {
          await this.getAndSetUserRoles();
          this.$router.push(redirectPath);
        })
        .catch((err) => {
          console.error(err);
          this.$router.push("/oidc-callback-error"); // Handle errors any way you want
        });
    },
  });
</script>
