import { defineStore } from "pinia";
import { ref } from "vue";
import { LOSDocument } from "@/models/opal";

export const useDocumentsTabStore = defineStore("documentsTab", () => {
  const documents = ref<Array<any>>([]);
  const currentlyEditingDocIds = ref<Array<number>>([]);
  const currentlyEditingDocOwners = ref<Array<number>>([]);
  const documentPdfModalOpen = ref<boolean>(false);

  function isEditDisabled(document: LOSDocument) {
    return document.documentStatusTypeName === "Accepted";
  }

  function updateDocument(documentId: number, updatedDocument: Partial<LOSDocument>) {
    const index = documents.value.findIndex(
      (item) => item.documentId === documentId
    );

    if (index !== -1) {
      documents.value[index] = { ...documents.value[index], ...updatedDocument };
    }
  }

  function toggleEditMode(documentId: number, applicantId: number) {
    const docIndex = currentlyEditingDocIds.value.indexOf(documentId);
    const ownerIndex = currentlyEditingDocOwners.value.indexOf(applicantId);
    
    if (docIndex === -1 && ownerIndex === -1) {
      currentlyEditingDocIds.value.push(documentId);
      currentlyEditingDocOwners.value.push(applicantId);
    } else {
      untoggleEditMode(documentId);
    }
  }

  function untoggleEditMode(documentId: number) {
    const index = currentlyEditingDocIds.value.indexOf(documentId);
    if (index !== -1) {
      currentlyEditingDocIds.value.splice(index, 1);
      currentlyEditingDocOwners.value.splice(index, 1);
    }

  }

  return {
    documents,
    updateDocument,
    isEditDisabled,
    currentlyEditingDocIds,
    currentlyEditingDocOwners,
    toggleEditMode,
    untoggleEditMode,
    documentPdfModalOpen,
  };
});