import { createStore, storeKey } from "vuex";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings, getEnvironment } from "@/config/oidc";
import { WebStorageStateStore } from "oidc-client";
import useSignOut from "./use/signout";
import incomeTabModule from "@/store/incomeTabModule";
import documentTabModule from '@/store/documentTabModule';


const oidcMainSettings = {
	...getEnvironment(),
	...oidcSettings,
	userStore: new WebStorageStateStore({
		store: window.sessionStorage,
	}),
};

const oidcStoreSettings = {
	namespaced: true,
	dispatchEventsOnWindow: true,
};

const oidcEventListeners = {
	userLoaded: (user: any) => console.log("OIDC User Loaded:", user),
	userUnloaded: () => console.log("OIDC User Unloaded"),
	accessTokenExpiring: () => console.log("Access token will expire"),
	accessTokenExpired: () => {
		console.log("Access token expired.");
		const { signOut } = useSignOut();
		signOut();
	},
	silentRenewError: () => console.log("OIDC User Unloaded"),
	userSignedOut: () => console.log("OIDC User Signed Out"),
	oidcError: (payload: any) => console.log("OIDC Error", payload),
	automaticSilentRenewError: (payload: any) => console.log("OIDC Automatic Silent Renew Error", payload)
};

export default createStore({
	modules: {
		modal: {},
		pdfModal: {},
		oidcStore: vuexOidcCreateStoreModule(oidcMainSettings, oidcStoreSettings, oidcEventListeners),
		types: {},
		incomeTab: incomeTabModule,
		documentTab: documentTabModule
	}
});