interface EnrollmentStatus {
  value: number;
  label: string;
}

const EnrollmentStatuses: EnrollmentStatus[] = [
  { value: 1, label: "Full Time" },
  { value: 2, label: "Half Time" },
  { value: 3, label: "Less Than Half" },
  { value: 4, label: "Three Quarter" },
  { value: 5, label: "Withdrawn" },
  { value: 6, label: "Graduated" },
  { value: 7, label: "Approved Leave of Absence" },
  { value: 8, label: "Not Specified" }
];

export default EnrollmentStatuses;
