export interface ApplicationDetail
{
    isFullService?: boolean;
    hasRecommendation?: boolean;
    analystRecomendation?: string;
    lenderName?: string;
}



export enum NationalIdNumberTypes
{
    SSN=1,
    ITIN=2
}