
import { defineComponent } from 'vue';
import Page from '../components/layout/Page.vue';
import Loader from '@/components/Loader.vue';
import { $api } from '@/services/api';

export default defineComponent({
	components: {
		Page,
		Loader,
	},
	data: () => ({
		loading: true as boolean,
	}),
	async mounted() {
		this.loading = false;
	},
});
