import { defineStore } from 'pinia'
import { ref } from 'vue'
import { LOSApplication } from "@/models/opal";
import { School } from "@/types/school";

export const useApplicationStore = defineStore('application', () => {
  const application = ref<LOSApplication>({} as LOSApplication);
  const canEdit = ref(false);
  const school = ref<School>({} as School);

  const toggleCanEdit = () => {
    canEdit.value = !canEdit.value;
  };

  return { application, canEdit, school, toggleCanEdit}
})