import { CustomPricing } from "./customPricing";

export interface Override
{
    overrideTypeId?: number;
    pricingTierIds?: number[];
    customPricing?: CustomPricing;
    locLimit?: string;
    freeFormReason?: string;
    reviewBy?: string;
    reviewDateTime?: string;
    isReviewed: boolean;
    isCustomPricing: boolean;
}

export enum OverrideTypes
{
    LOCLimitMod = 10
}




