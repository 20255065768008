import { defineStore } from "pinia";
import axios from "axios";

interface DisbursementBatch {
	id: number;
	createdAtUtcFormatted: string;
	approvedBy: string;
	approvedAtUtcFormatted: string;
	status: number;
	prefundReportDateFormatted: string;
	disbursementReportDateFormatted: string;
	accountingReportDateFormatted: string;
}

interface Disbursement {
	id: string;
	inBatch: boolean;
	lenderId: number[] | null;
	borrowerName: string;
	disbursedAmountFormatted: string;
	disbursementMethod: string;
	transactionDateFormatted: string;
	referenceId: string;
	lenderUseOnly: string;
	sequenceNumber: string;
}

interface Lender {
	id: number;
	name: string;
	displayName: string;
	location: string;
}

interface BatchData {
	disbursementBatch: DisbursementBatch;
	disbursements: Disbursement[];
}

export const useBatchesStore = defineStore({
	id: "batchesStore",

	state: () => ({
		// initialize state
		batchData: null as BatchData | null,
		lenders: [] as Lender[]
	}),

	actions: {
		getBatchId() {
			return this.batchData?.disbursementBatch.id;
		},

		// set the batch data
		setBatchData(data: BatchData | null) {
			this.batchData = data;
		},

		// clear the batch data
		clearBatchData() {
			this.batchData = null;
		},

		setLenders(lenders: Lender[]) {
			this.lenders = lenders;
		},

		updateDisbursementInBatch(disbursementId: string, inBatch: boolean) {
			if (this.batchData && this.batchData.disbursements) {
				const index = this.batchData.disbursements.findIndex(
					(disbursement) => disbursement.id === disbursementId
				);
				if (index !== -1) {
					this.batchData.disbursements[index].inBatch = inBatch;
				}
			}
		},

		async searchDisbursementBatches(searchParams) {
			try {
				const response = await axios.post(
					"/api/disbursementBatches/search",
					searchParams
				);
				this.setBatchData(response.data);
			} catch (error) {
				console.error(error);
			}
		},
		async loadLenders() {
			try {
				const response = await axios.get("/api/lenders");
				this.setLenders(response.data);
			} catch (error) {
				console.error(error);
			}
		},
	},
});
