import DOMPurify from "dompurify";
import { $api } from "@/services/api1";
import store from "../store";
export default function useFunctions(): any {
	function decodeHTMLEncodedStr(text: string) {
		const textArea = document.createElement('textarea');
		if (text) {
			// Extra pre-processing to remove {{ }} from content prior to sanitizing
			// potential limitation is if emails or passwords have curly braces...need to determine how we want to handle this -LS06302023
			const newText = (text || "").replaceAll(/\{|\}/gi, '');

			// remove amp;
			const cleanedText = newText.replaceAll("amp;", '');

			textArea.innerHTML = DOMPurify.sanitize(cleanedText);
			return textArea.value;
		}
	}
	function checkLocalStorage(key) {
		///////////////////////////////////////////////////////////////////////////////////////////
		// description: checks the localStorage to determine if an item exits and is not expired //
		///////////////////////////////////////////////////////////////////////////////////////////
		// if the localstore has the type
		if (localStorage.getItem(key) !== null) {
			const localcache = JSON.parse(`${localStorage.getItem(key)}`);
			// if the data has been cached for less than 1 day (86400000 ms)
			if ((Date.now() - localcache.timestamp) < 86400000) {
				// return the localstorage data
				return localcache;
			}
		}

		// return as false if localstorage check fails
		return false;
	}
	function removeDuplicatesFromArray(arr: any) {
		const unique = arr.reduce(function (acc: any, curr: any) {
			if (!acc.includes(curr))
				acc.push(curr);
			return acc;
		}, []);
		return unique;
	}
	function removeObjectWithId(arr: Array<any>, id: number) {
		const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

		if (objWithIdIndex > -1) {
			arr.splice(objWithIdIndex, 1);
		}

		return arr;
	}
	function replaceObjectWithId(arr: Array<any>, id: number, newObj: object) {
		const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

		if (objWithIdIndex > -1) {
			arr[objWithIdIndex] = Object.assign({}, newObj);
		}

		return arr;
	}
	function removeElement(arr: Array<any>, val: any) {
		const index = arr.indexOf(val);
		arr.splice(index, 1);
	}
	async function getSchoolLevelsByProgramTypeId(programTypeId: number) {
		const key = 'SchoolLevels';
		const localData1 = checkLocalStorage(`cache.${key}1`);
		const localData2 = checkLocalStorage(`cache.${key}2`);
		if (localData1 && localData2) {
			const localData = programTypeId === 2 ? localData2.data : localData1.data;
			localStorage.setItem(`cache.${key}`, JSON.stringify({ timestamp: Date.now(), data: localData }));
		} else {
			await getAllSchoolLevels();
			const localData = programTypeId === 2 ? localData2.data : localData1.data;
			localStorage.setItem(`cache.${key}`, JSON.stringify({ timestamp: Date.now(), data: localData }));
		}
	}

	async function getAllSchoolLevels() {
		const key = 'SchoolLevels';
		const localData1 = checkLocalStorage(`cache.${key}1`);
		const localData2 = checkLocalStorage(`cache.${key}2`);
		if (localData1 === false || localData2 === false) {
			const ugloc = await $api.types.getTypeByProgram("SchoolLevels", { programTypeId: 1 });
			const dgloc = await $api.types.getTypeByProgram("SchoolLevels", { programTypeId: 2 });

			if (ugloc?.results?.length > 0) {
				localStorage.setItem(`cache.${key}1`, JSON.stringify({ timestamp: Date.now(), data: ugloc }));
				store.state.types[`${key}1`] = ugloc;
			}
			if (dgloc?.results?.length > 0) {
				localStorage.setItem(`cache.${key}2`, JSON.stringify({ timestamp: Date.now(), data: dgloc }));
				store.state.types[`${key}2`] = dgloc;
			}
		}

		if (localData1 && localData2) {
			if (localData1) {
				store.state.types[`${key}1`] = localData1.data;
			}
			if (localData2) {
				store.state.types[`${key}2`] = localData2.data;
			}
		}
	}
	async function getDocServiceType(type: string) {
		const localData = checkLocalStorage(`cache.${type}`);
		// if the type is stored in localstorage
		if (localData) {
			store.state.types[type] = localData.data;
		} else {
			const response = await $api.types.getDocServiceType(type);
			if (response.count > 0) {
				localStorage.setItem(`cache.${type}`, JSON.stringify({ timestamp: Date.now(), data: response }));
				store.state.types[type] = response;
			}
		}
	}
	async function getStipulationTypes() {
		const localData = checkLocalStorage(`cache.StipulationTypes`);
		// if the type is stored in localstorage
		if (localData) {
			store.state.types['StipulationTypes'] = localData.data;
		} else {
			const response = await $api.types.getStipulationTypes();
			if (response && response.length > 0) {
				localStorage.setItem(`cache.StipulationTypes`, JSON.stringify({ timestamp: Date.now(), data: response }));
				store.state.types['StipulationTypes'] = response;
			}
		}
	}
	function convertYearToDateTime(year: number) {
		const dt = new Date(year, 0, 1);
		return dt;
	}

	function sortLosTypesByName(array: Array<any>) {
		// only sort if array has items
		if (array && array.length > 0) {
			array.sort((a: any, b: any) => {
				if (b.name < a.name) return 1;
				if (b.name > a.name) return -1;
				return 0;
			});
			return array;
		} else {
			return [];
		}
	}

	function sortLosTypesByDescription(array: Array<any>) {
		if (array && array.length > 0) {
			array.sort((a: any, b: any) => {
				if (b.description < a.description) return 1;
				if (b.description > a.description) return -1;
				return 0;
			});
			return array;
		} else {
			return [];
		}
	}

	function getInitial(name) {
		if (typeof name !== "undefined" && name !== null) {
			return name.charAt(0);
		} else {
			return "";
		}
	}

	async function getGraduateDegreePrograms() {
    const key = 'GraduateDegreePrograms';

    try {
        const localData = checkLocalStorage(`cache.${key}`);

        if (localData) {
            store.state.types[key] = localData.data;
        } else {
					const graduatePrograms = await $api.schools.getGraduateDegreePrograms();

            localStorage.setItem(`cache.${key}`, JSON.stringify({ timestamp: Date.now(), data: graduatePrograms.data }));

            store.state.types[key] = graduatePrograms.data;
        }
    } catch (error) {
        return false;
    }
}

	return {
		convertYearToDateTime,
		checkLocalStorage,
		decodeHTMLEncodedStr,
		getDocServiceType,
		getStipulationTypes,
		removeDuplicatesFromArray,
		removeObjectWithId,
		replaceObjectWithId,
		removeElement,
		sortLosTypesByName,
		sortLosTypesByDescription,
		getInitial,
		getAllSchoolLevels,
		getSchoolLevelsByProgramTypeId,
		getGraduateDegreePrograms
	}
}