import store from "@/store";
import { Option } from "@/models/form";


export default function useFormHelpers(): any {
  function yearOptions(n: any) {
		const currentYear = new Date().getFullYear();
		const years = [] as number[];
		for (let i = 0; i < n; i++) {
			const year = currentYear - i;
			years.push(year)
		}
	 
		return years.map((year: number) => {
			return {
				label: year,
				value: year
			}
		})
	}

	function documentStatusReasonsOptions(status: string) {
		let documentStatusReasonsOptions = store?.state.types["DocumentStatusReasons"]?.results;

		if (status === 'Rejected') {
			return documentStatusReasonsOptions = store?.state.types["DocumentStatusReasons"]?.results
			.filter((dsr: any) => 
			dsr.name !== 'Unique Document Type' &&
			dsr.name !== 'Unsure of Document Information' &&
			dsr.name !== 'Partially Usable Document' &&
			dsr.name !== 'Other'
			)
			.map((o: any) => {
				return {
					label: o.name,
					value: o.id
				} as Option;
			});
		}

		if (status === 'Escalated for Manager Review') {
			return documentStatusReasonsOptions = store?.state.types["DocumentStatusReasons"]?.results
				.filter((dsr: any) => 
				dsr.name !== 'Unreadable / Blurry' && 
				dsr.name !== 'Incomplete Image' &&
				dsr.name !== 'Not Usable for Verification' &&
				dsr.name !== 'Data Incomplete'
				)
				.map((o: any) => {
					return {
						label: o.name,
						value: o.id
					} as Option;
				});
		}
	}


  return {
		documentStatusReasonsOptions,
    yearOptions
  }
}