<template>
	<loader v-if="loading" />
	<page v-else>
		<div class="xdashboard-tile-wrapper" style="margin-bottom: 1.5%">
			<div style="display: flex; margin-top: 1.5%">
				<div
					style="
						width: 64%;
						margin: 0 1.5%;
						background: white;
						margin-right: 0;
						padding: 2%;
						border-radius: 15px;
						box-shadow: 1px 1px 5px #00000036;
					">
					<div style="display: flex">
						<div style="width: 25%">
							<h3 style="padding-bottom: 30px">Dashboard</h3>

							<h2 style="margin-bottom: 0">
								{{ statistics.totalApplications || '—' }}
							</h2>
							<div style="padding-bottom: 30px">Total Applications</div>

							<h2 style="margin-bottom: 0">
								{{ statistics.totalLoans || '—' }}
							</h2>
							<div style="padding-bottom: 30px">Total Loans</div>

							<h2 style="margin-bottom: 0">
								{{ statistics.totalLoanAmount || '—' }}
							</h2>
							<div>Total Loan Amount</div>
						</div>
						<div style="width: 75%">
							<h3 style="padding-bottom: 30px">Last 6 Months</h3>
							<canvas id="line-chart"></canvas>
						</div>
					</div>
				</div>
				<div
					style="
						background: white;
						width: 31%;
						margin-left: 2%;
						padding: 2%;
						border-radius: 15px;
						box-shadow: 1px 1px 5px #00000036;
					">
					<h3 style="padding-bottom: 30px">Open Tasks</h3>
					<canvas id="doughnut-chart" height="250"></canvas>
					<div style="display: none">
						<h2>25%</h2>
					</div>
				</div>
			</div>
			<div style="padding: 1.5%; padding-bottom: 0; display: none">
				<img
					src="@/assets/images/placeholder-dashboard.png"
					style="width: 100%" />
			</div>
			<div style="display: flex; margin-top: 1.5%">
				<div class="xdashboard-tile">
					<h3>Recently Viewed Applications</h3>
					<div v-if="$.recentlyViewed.length > 0">
						<table class="xrecentlyviewed">
							<tr
								v-for="(item, index) in $.recentlyViewed
									.filter((item) => item.category == 'Application')
									.slice(0, 10)"
								:key="index">
								<td style="width: 40px">
									<div class="xusericon" data-size="small">
										<div class="xusericon-picture">
											{{ item.firstName?.charAt(0) || ''
											}}{{ item.lastName?.charAt(0) || '' }}
										</div>
										<div class="xusericon-gender" data-gender=""></div>
									</div>
								</td>
								<td>
									<div style="float: right">
										{{ $moment(item.timestamp).fromNow() }}
									</div>
									<div>
										<div>
											<span
												v-html="item.name || 'No Name'"
												style="vertical-align: middle"></span>
											<span class="xinfopill" :data-type="item.category">{{
												item.category
											}}</span>
										</div>
										<h5 v-html="functions.getLenderName(item.lenderId)"></h5>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div v-else>There are no recently viewed applications.</div>
				</div>
				<div class="xdashboard-tile">
					<h3>Applications By Lender</h3>
					<table class="xrecentlyviewed">
						<tr
							v-for="(item, index) in Object.entries(
								statistics.totalApplicationsByLender
							).sort()"
							:key="index">
							<td style="width: 40px">
								<div class="xusericon" data-size="small">
									<div
										class="xusericon-picture"
										style="background: transparent"></div>
								</div>
							</td>
							<td>
								<div style="float: right">{{ item[1] }}</div>
								<div>{{ functions.getLenderName(item[0]) }}</div>
							</td>
						</tr>
					</table>
				</div>
				<div class="xdashboard-tile">
					<h3>Applications By Type</h3>
					<table class="xrecentlyviewed">
						<tr
							v-for="(item, index) in Object.entries(
								statistics.totalApplicationsByType
							).sort()"
							:key="index">
							<td style="width: 40px">
								<div class="xusericon" data-size="small">
									<div
										class="xusericon-picture"
										style="background: transparent"></div>
								</div>
							</td>
							<td>
								<div style="float: right">{{ item[1] }}</div>
								<div>{{ functions.displayProductType(item[0]) }}</div>
							</td>
						</tr>
					</table>
				</div>
			</div>

			<div style="display: flex; margin-top: 1.5%">
				<div class="xdashboard-tile">
					<h3>Recently Viewed Loans</h3>
					<div v-if="$.recentlyViewed.length > 0">
						<table class="xrecentlyviewed">
							<tr
								v-for="(item, index) in $.recentlyViewed
									.filter((item) => item.category == 'Loan')
									.slice(0, 10)"
								:key="index">
								<td style="width: 40px">
									<div class="xusericon" data-size="small">
										<div class="xusericon-picture">
											{{ item.firstName?.charAt(0) || ''
											}}{{ item.lastName?.charAt(0) || '' }}
										</div>
										<div class="xusericon-gender" data-gender=""></div>
									</div>
								</td>
								<td>
									<div style="float: right">
										{{ $moment(item.timestamp).fromNow() }}
									</div>
									<div>
										<div>
											<span
												v-html="item.name || 'No Name'"
												style="vertical-align: middle"></span>
											<span class="xinfopill" :data-type="item.category">{{
												item.category
											}}</span>
										</div>
										<h5 v-html="functions.getLenderName(item.lenderId)"></h5>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div v-else>There are no recently viewed loans.</div>
				</div>
				<div class="xdashboard-tile">
					<h3>Loans By Lender</h3>
					<table class="xrecentlyviewed">
						<tr
							v-for="(item, index) in Object.entries(
								statistics.totalApplicationsByLender
							).sort()"
							:key="index">
							<td style="width: 40px">
								<div class="xusericon" data-size="small">
									<div
										class="xusericon-picture"
										style="background: transparent"></div>
								</div>
							</td>
							<td>
								<div style="float: right">{{ item[1] }}</div>
								<div>{{ functions.getLenderName(item[0]) }}</div>
							</td>
						</tr>
					</table>
				</div>
				<div class="xdashboard-tile">
					<h3>Loans By Type</h3>
					<table class="xrecentlyviewed">
						<tr
							v-for="(item, index) in Object.entries(
								statistics.totalApplicationsByType
							).sort()"
							:key="index">
							<td style="width: 40px">
								<div class="xusericon" data-size="small">
									<div
										class="xusericon-picture"
										style="background: transparent"></div>
								</div>
							</td>
							<td>
								<div style="float: right">{{ item[1] }}</div>
								<div>{{ functions.displayProductType(item[0]) }}</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</page>
</template>

<script setup>
	import { ref, onMounted, reactive, inject } from 'vue';
	import store from '@/store';
	import page from '@/components/layout/Page.vue';
	import loader from '@/components/Loader.vue';
	import { Chart } from 'chart.js';
	import axios from 'axios';
	import { useRouter, useRoute } from 'vue-router';
	import functions from '@/use/functions.js';
	import PermissionsService from '@/services/api1'


	var route = useRoute();
	var router = useRouter();
	var applicationData = $ref(null);
	var loanData = $ref(null);
	
	var $ = reactive({
		recentlyViewed: [],
	});
	
	var statistics = reactive({
		statusType: {},
		applicationsByMonth: {},
		totalApplications: false,
		totalApplicationsByLender: {},
		totalApplicationsByType: {},
		totalLoans: false,
		totalLoanAmount: false,
		totalLoansByLender: false,
	});
	
	const rolePermission = ref([]);
	const loading = ref(false);
	const error = ref(null);

	const permissionsService = new PermissionsService();

	async function fetchRolePermissions() {
		const permissionsService = new PermissionsService();
		try {
			const permissions = await permissionsService.getRolePermissions();
			rolePermission.value = permissions;
		} catch (error) {
			console.error(`Error fetching role permissions:`, error);
		}
	}

	async function getApplications() {
		await axios(`/api/applications/dashboard/search`, {
			method: 'POST',
			require: 'json',
			data: {
				StatusIds: [1, 2, 3, 4, 5, 6],
			},
		})
			.then((response) => {
				var data = response.data;

				// make sure the applications data is not empty
				if (data.length > 0 && Array.isArray(data)) {
					// get the toal number of applications
					statistics.totalApplications = data.length;

					var currentMonth = new Date().getMonth() + 2;
					var currentYear = new Date().getFullYear();

					for (let i = 1; i < 7; i++) {
						var thisMonth = currentMonth - i;
						var thisYear = currentYear;

						// if the month value is 0 or negative
						if (thisMonth < 1) {
							// add 12 months to the value
							thisMonth = thisMonth + 12;

							// subtract 1 from the year
							thisYear = currentYear - 1;
						}

						thisMonth = ('0' + thisMonth).slice(-2);

						statistics.applicationsByMonth[`${thisYear}-${thisMonth}`] = 0;
					}

					// for each of the applications
					data.forEach((application) => {
						application.applicants.forEach((applicant) => {
							// if this is the primary borrower
							if (applicant.applicantTypeId === 1) {
								// set the full name of the borrower (put the two names in an array, filter out the null names, and join with a space)
								application.name =
									[applicant.firstName, applicant.lastName]
										.filter((n) => n)
										.join(' ') || 'No Name';

								// set the initials of the borrower
								application.initials = `${
									applicant.firstName?.charAt(0) || ''
								}${applicant.lastName?.charAt(0) || ''}`.trim();
							} else if (applicant.applicantTypeId === 2) {
								application.nameCoApplicant = applicant.firstName
									? `${applicant.firstName} ${applicant.lastName}`
									: 'No Name';
							}
						});

						statistics.statusType[application.statusName] =
							(statistics.statusType[application.statusName] || 0) + 1;

						// get the year-month of this application
						var yearMonth = application.createdAtUtc.substr(0, 7);

						if (statistics.applicationsByMonth[yearMonth] !== undefined) {
							statistics.applicationsByMonth[yearMonth] =
								(statistics.applicationsByMonth[yearMonth] || 0) + 1;
						}

						statistics.totalApplicationsByLender[application.lenderId] =
							(statistics.totalApplicationsByLender[application.lenderId] ||
								0) + 1;
						statistics.totalApplicationsByType[application.productId] =
							(statistics.totalApplicationsByType[application.productId] || 0) +
							1;

						// if the lenders api has already loaded
						if (store.state.types['Lenders']) {
							// for each of the lenders that matches the current application's lenderId
							store.state.types['Lenders']
								.filter((lender) => lender.id == application.lenderId)
								.forEach((lender) => {
									// set the application lenderName
									application.lenderName = lender.name;
								});
						}
					});
				}

				applicationData = data;
			})
			.catch((error) => {
				applicationData = 'Error Loading Results';
				console.log(`API Error: /api/applications/dashboard/search/`, error);
			});
	}

	async function getLoans() {
		await axios(`/api/loanservice/loans/filter`, {
			method: 'POST',
			require: 'json',
			data: {},
		})
			.then((response) => {
				var data = response.data;

				// make sure the loans data is not empty
				if (data) {
					// get the toal number of loans
					statistics.totalLoans = data.totalCount;

					statistics.totalLoanAmount = 0;

					// for each of the loans
					data.items.forEach((item) => {
						statistics.totalLoanAmount += item.loanAmount;
					});
				}

				loanData = data;
			})
			.catch((error) => {
				loanData = 'Error Loading Results';
				console.log(`API Error: /api/loanservice/loans/filter/`, error);
			});
	}

	async function getRecentlyViewed() {
		// if the localstorage has any recently viewed loans
		if (localStorage.getItem('cache.RecentlyViewedPages')) {
			// retreive the data
			var recentLoans = JSON.parse(
				localStorage.getItem('cache.RecentlyViewedPages')
			);

			// append to recentlyViewed
			$.recentlyViewed = $.recentlyViewed.concat(recentLoans);
		}

		$.recentlyViewed = $.recentlyViewed.sort((a, b) => {
			return b.timestamp - a.timestamp;
		});
	}

	

	onMounted(async (e) => {

		router.push({ path: '/applications' });

		loading.value = false;

		functions.getLenders();
		functions.getType('Products');
		await getApplications();
		await getLoans();
		await getRecentlyViewed();
		await fetchRolePermissions();

		setTimeout(function () {
			new Chart(document.getElementById('line-chart'), {
				type: 'line',
				data: {
					labels: Object.keys(statistics.applicationsByMonth).reverse(),
					datasets: [
						{
							label: 'Applications',
							data: Object.values(statistics.applicationsByMonth).reverse(),
							borderColor: function (context) {
								const chart = context.chart;
								const { ctx, chartArea } = chart;

								if (!chartArea) {
									// this case happens on initial chart load
									return;
								}

								var gradient;

								gradient = ctx.createLinearGradient(700, 0, 0, 0);
								gradient.addColorStop(0, '#5600ff');
								gradient.addColorStop(1, '#5600ff');

								return gradient;
							},
							fill: true,
							backgroundColor: function (context) {
								const chart = context.chart;
								const { ctx, chartArea } = chart;

								if (!chartArea) {
									// this case happens on initial chart load
									return;
								}

								var gradient;

								gradient = ctx.createLinearGradient(0, 450, 0, 150);
								gradient.addColorStop(0, 'transparent');
								gradient.addColorStop(1, 'transparent');

								return gradient;
							},
							borderWidth: 5,
							hoverBorderWidth: 6,
							pointHitRadius: 50,
							pointRadius: 10,
							pointBorderColor: 'transparent',
							pointBackgroundColor: 'transparent',
							pointHoverBorderColor: 'white',
							pointHoverBackgroundColor: '#5600ff',
							pointHoverBorderWidth: 4,
							pointHoverRadius: 13,
							tension: 0,
						},
					],
				},
				options: {
					responsive: true,
					legend: {
						display: false,
					},
					plugins: {
						legend: {
							position: 'top',
						},
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									color: '#dddddd',
								},
							},
						],
						yAxes: [
							{
								gridLines: {
									color: '#dddddd',
								},
								ticks: {
									precision: 0,
									beginAtZero: true,
								},
							},
						],
					},
				},
			});

			new Chart(document.getElementById('doughnut-chart'), {
				type: 'doughnut',
				data: {
					labels: Object.keys(statistics.statusType),
					datasets: [
						{
							label: 'My First Dataset',
							data: Object.values(statistics.statusType),
							backgroundColor: ['#875ec0', '#eb4885', '#feb62a'],
							height: '300px',
							borderWidth: 0,
						},
					],
				},
				options: {
					cutoutPercentage: 75,
					legend: {
						display: true,
						position: 'bottom',
					},
				},
			});
		}, 500);
	});

</script>

