<template>
	<loader v-if="loading" />
	<page v-else>
		<div class="xpage-breadcrumbs">
			<router-link to="/dashboard" class="icon-home"></router-link>
			<span>></span> <router-link to="/lenders">Lenders</router-link>
		</div>

		<div class="xpage-inner">
			<h2>Add A Lender</h2>

			<div class="xheader-menu">
				<div
					class="xheader-menu-item"
					v-for="(box, index) in [1, 2, 3, 4]"
					:key="index">
					<div>
						<div style="display: flex">
							<div>
								<i
									class="icon-ok-circle"
									style="font-size: 46px; color: #0eb066"></i>
							</div>
							<div>
								<div class="xdisplaybar"></div>
							</div>
						</div>
						<h3>Step 1</h3>
						<h2>Basic Info</h2>
						<span>Completed</span>
					</div>
				</div>
			</div>

			<div style="display: flex">
				<div class="xform-left">
					<form autocomplete="off">
						<fieldset>
							<div class="xform-section">
								<h3 style="margin-bottom: 15px">Basic Info</h3>
								<div class="xfields">
									<div class="xfield">
										<label>Lender Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>Middle Name</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>Last Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>SSN</label>
										<input type="text" />
									</div>
									<div class="xfield" v-if="1 == 3">
										<label>Citizenship Status</label>
										<select
											v-model="
												applicants[applicants.selected].citizenshipStatusId
											"
											disabled>
											<option
												v-for="(citizen, index) in store.state.types[
													'Citizenship'
												]?.results"
												:key="index"
												:value="citizen.id">
												{{ citizen.name }}
											</option>
										</select>
									</div>
									<div class="xfield">
										<label>Lender Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>Middle Name</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>Last Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>SSN</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>SSN</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>Lender Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>Middle Name</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>Last Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>SSN</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>SSN</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>Lender Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>Middle Name</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>Last Name<i>*</i></label>
										<input type="text" required />
									</div>
									<div class="xfield">
										<label>SSN</label>
										<input type="text" />
									</div>
									<div class="xfield">
										<label>SSN</label>
										<input type="text" />
									</div>
								</div>
							</div>
						</fieldset>

						<button class="xlarge">Previous</button>
						<button class="xlarge" data-color="green">Next</button>
					</form>
				</div>
				<div class="xform-right">
					<div class="xfloatybox">
						<div class="xfloatybox-bar">
							<div class="xfloatybox-bar-percent"></div>
						</div>
						<h3>31 Fields Remaining</h3>
						<h2>25%</h2>
						<h4>Complete</h4>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<script setup>
	import { onMounted } from 'vue';
	import page from '@/components/layout/Page.vue';
	import loader from '@/components/Loader.vue';
	import { $api } from '@/services/api';
	import useFunctions from '@/use/functions1';

	const { decodeHTMLEncodedStr } = useFunctions();

	var loading = $ref(true);

	onMounted(async () => {
		loading = false;
	});
</script>

<style lang="scss" scoped>
	div.xheader-menu {
		display: flex;
	}

	div.xheader-menu-item h2 {
		font-size: 22px;
		margin: 0;
	}

	div.xheader-menu-item h3 {
		font-size: 11px;
		margin: 0;
		text-transform: uppercase;
		color: #929292;
		margin-top: 10px;
	}

	div.xheader-menu-item span {
		display: inline-block;
		font-size: 12px;
		border-radius: 50px;
		background: #d2e8e1;
		color: #0eb066;
		padding: 5px 15px;
		margin-top: 7px;
	}

	div.xdisplaybar {
		width: 150px;
		background: #0eb066;
		height: 5px;
		margin: 0 11px;
		margin-top: 26px;
		border-radius: 50px;
	}

	div.xform-left {
		max-width: 900px;
	}

	div.xform-right {
		margin-left: 20px;
	}

	div.xfloatybox {
		background: white;
		width: 250px;
		text-align: center;
		padding-bottom: 20px;
	}

	div.xfloatybox h3 {
		font-family: 'Montserrat', arial, sans-serif;
		text-transform: uppercase;
		font-size: 15px;
	}

	div.xfloatybox h2 {
		margin: 0;
		font-size: 56px;
	}

	div.xfloatybox h4 {
		font-family: 'Montserrat', arial, sans-serif;
		text-transform: uppercase;
		font-size: 15px;
	}

	div.xfloatybox-bar {
		background: #dddddd;
		height: 8px;
		margin-bottom: 15px;
	}

	div.xfloatybox-bar-percent {
		background: #0eb066;
		height: 8px;
		width: 45%;
	}
</style>
