
	import { defineComponent } from 'vue';
	import { mapGetters, mapState } from 'vuex';
	import store from '../store';
	import { getEnvironment } from '@/config/oidc';
	import Dashboard from '@uppy/dashboard';
	import XHRUpload from '@uppy/xhr-upload';
	import Uppy from '@uppy/core';
	import '@uppy/core/dist/style.css';
	import '@uppy/dashboard/dist/style.css';
	import { $api } from '@/services/api1';
	import { Permissions, PermissionLevels } from '@/models/permissions';
	import { usePermissionsStore } from '@/store/store/permissionsStore';

	export default defineComponent({
		props: {
			name: { type: String },
			applicationId: { type: Number },
			applicantId: { type: Number },
			userId: { type: String },
			documentTypeId: { type: Number }
		},
		mounted() {
			this.instantiateUppy();
		},
		emits: ["getDocuments", "update:modelValue","uploadSuccess"],
		data() {
			return {
				payload: null,
				previewPath: null,
				disabled: true,
				title: null,
				fileUploadOpen: false,
				errorMessage: '',
				errorMessages: [
					'File format for {{currentFile.name}} is not supported. Please upload a new file in one of these formats: PDF, PNG, JPEG/JPG, TIF/TIFF, .doc/docx',
					'',
				],
			};
		},
		methods: {
			instantiateUppy() {
				const uppy = new Uppy({
					id: this.uppyId,
					debug: false,
					autoProceed: false,
					allowMultipleUploads: false,
					infoTimeout: 6000, // 6 seconds
					locale: {
						strings: {
							exceedsSize:
								'%{file} is too large. Maximum supported file size is 100MB. Please reduce the file size and try again.',
							noInternetConnection: `Please check your internet connection and try again. If the issue continues and your internet connection is working, please try again later or contact our support team.`,
							failedToUpload: `An error occurred while processing your file. Please try again. If the issue continues, please contact our support team`,
						},
					},
					restrictions: {
						maxFileSize: 100000000, // 100MB
						maxNumberOfFiles: 1,
					},
					meta: {
						applicationId: this.applicationId,
						applicantId: this.applicantId,
						userId: this.userId,
						documentTypeId: this.documentTypeId || 23,
                    },
                    onBeforeUpload: (files) => {
                        if (!this.userCanUpload()) {
                            this.errorMessage = `Insufficient permissions. Please contact your system administrator.`;
                            return false;
						}

						return true;
                    },
                    onBeforeFileAdded: (currentFile, files) => {
						let allowedFileTypes = [
							'doc',
							'docx',
							'pdf',
							'png',
							'jpg',
							'jpeg',
							'heic',
							'tif',
							'tiff',
							'xls',
							'xlsx',
						];

						const isValidFileType = allowedFileTypes.includes(currentFile.extension);

						if (isValidFileType === false) {
							this.errorMessage = `File format for ${currentFile.name} is not supported. Please upload a new file in one of these formats: PDF, PNG, JPEG/JPG, TIF/TIFF, .doc/docx.`;
							return false;
						}

						if (isValidFileType === true) {
							this.errorMessage = '';
							return true;
                        }

                        if (currentFile.size > 100000000) {
                            this.errorMessage = `${currentFile.name} is too large. Maximum supported file size is 100MB. Please reduce the file size and try again.`;
                            return false;
                        }

						// Reset error message
						setTimeout(() => {
							console.log('Delayed for 5 seconds');
							this.errorMessage = '';
						}, 5000);
					}
				}).use(Dashboard, {
					proudlyDisplayPoweredByUppy: false,
					showLinkToFileUploadResult: true,
					disableInformer: true, // custom error logging
					target: `#${this.uppyId}`,
					inline: true,
					showSelectedFiles: true,
					height: 400,
					disableThumbnailGenerator: false
				}).use(XHRUpload, {
					endpoint: `${getEnvironment().documents}/api/v1/DocUploadService/UploadDocument/`,
					formData: true,
					fieldName: 'documentToUpload',
					allowedMetaFields: null,
					bundle: true,
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
						Accept: '*/*',
					}
				});

				// Events for file uploads
				uppy.on('restriction-failed', (file, error) => {
					// do some customized logic like showing system notice to users
					console.log('Uppy Retriction Error', error);

					this.errorMessage = `${file?.name} is too large. Maximum supported file size is 100MB. Please reduce the file size and try again.`;
				});

				uppy.on('file-added', (file) => {
					console.log('File Added');
				});

				uppy.once('upload', (data) => {
					// Do something
					console.log('Uppy Upload', data);
				});

				uppy.on('error', (error) => {
					if (error) {
						// this.errorMessage = `${error.message}`;
						this.errorMessage = `An error occurred while processing your file. Please try again. If the issue continues, please contact our support team.`;
					}
				});

				uppy.on('complete', async (result) => {
					console.log('Successful Files:', result.successful);
					console.log('Failed Files:', result.failed);
					this.$emit('getDocuments');
					const file = result.successful[0];
				if (file) {
					this.$emit('uploadSuccess', {result: true});
				}
					if (this.applicantId) {
						// Check if uploaded document actually made it to documents table
						const documents = await $api.documents.getDocumentsByApplicant(file?.meta?.applicantId as number);
					}

					if (result.failed.length > 0) {
						this.errorMessage = `An error occurred while processing your file. Please try again. If the issue continues, please contact our support team.`;
					}
				});
			},
			dragover(event: any) {
				event.preventDefault();
				event.currentTarget.classList.add('active-drag');
			},
			dragleave(event: any) {
				event.currentTarget.classList.remove('active-drag');
			},
			drop(event: any) {
				event.preventDefault();
				this.$emit('update:modelValue', [...event.dataTransfer.files]);
				event.currentTarget.classList.remove('active-drag');
			},
			userCanUpload() {
				const permissionsStore = usePermissionsStore();
                // const userRoles: Role[] | undefined = store.state.types['UserRoles'];
                // const userRoles = permissionsStore.currentUser.roles;
						
				return permissionsStore.hasRequiredPermission([Permissions.DocumentProcessing], PermissionLevels.Create);
				//return hasRequiredPermission(userRoles, Permissions.DocumentProcessing, PermissionLevels.Create);
			}
		},
		computed: {
			...mapGetters('oidcStore', ['oidcUser']),
			...mapState(['configuration', 'lenderData']),
			uppyId(): string {
				return `uppy_${this.name}`;
			}
		},
	});
