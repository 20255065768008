import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "../store";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Applications from "../views/Applications.vue";
import Application from "../views/Application-Single.vue";
import Loans from "../views/Loans.vue";
import Loan from "../views/Loans-Single.vue";
import Administration from "../views/Administration.vue";
import Lenders from "../views/Lenders.vue";
import Bureaus from "../views/Bureaus.vue";
import Batches from "../views/Batches.vue";
import Programs from "../views/Programs.vue";
import Pricing from "../views/Pricing.vue";
import Settings from "../views/Settings.vue";
import Profile from "../views/Profile.vue";
import BatchEdit from "../components/disbursements/BatchEdit.vue";
import OidcCallback from "../views/OidcCallback.vue";
import OidcCallbackError from "../views/OidcCallbackError.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: `Home`,
    },
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      title: `Dashboard`,
    },
    component: Dashboard,
  },
  {
    path: "/applications",
    name: "Applications",
    meta: {
      title: "Applications",
    },
    component: Applications,
  },
  {
    path: "/applications/:referenceId",
    name: "Application",
    meta: {
      title: `Application`,
    },
    component: Application,
  },
  {
    path: "/loans",
    name: "Loans",
    meta: {
      title: `Loans`,
    },
    component: Loans,
  },
  {
    path: "/batches",
    name: "Batches",
    meta: {
      title: `Disbursement Batches`,
    },
    component: Batches,
  },
  {
    path: "/disbursementbatches/:batchNumber",
    name: "BatchEdit",
    meta: {
      title: `Batch Edit`,
    },
    component: BatchEdit,
  },
  {
    path: "/loans/:referenceId",
    name: "Loan",
    meta: {
      title: `Loan`,
    },
    component: Loan,
  },
  {
    path: "/administration",
    name: "Administration",
    meta: {
      title: `Administration`,
    },
    component: Administration,
  },
  {
    path: "/lenders",
    name: "Lenders",
    meta: {
      title: `Lenders`,
    },
    component: Lenders,
  },
  {
    path: "/bureaus",
    name: "Bureaus",
    meta: {
      title: `Bureaus`,
    },
    component: Bureaus,
  },
  {
    path: "/programs",
    name: "Programs",
    meta: {
      title: `Programs`,
    },
    component: Programs,
  },
  {
    path: "/pricing",
    name: "Pricing",
    meta: {
      title: `Pricing`,
    },
    component: Pricing,
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {
      title: `Settings`,
    },
    component: Settings,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      title: `Profile`,
    },
    component: Profile,
  },
  {
    path: "/loggedout",
    name: "loggedout",
    component: () => import("../views/LoggedOut.vue"),
    meta: {
      isPublic: true,
      title: "Logged Out",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
    meta: {
      isPublic: true,
      title: "Log Out",
    },
  },
  {
    // needs to match redirectUri in oidcSettings
    path: "/oidc-callback",
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isPublic: true,
      title: "Redirecting...",
    },
  },
  {
    // needs to match redirect_uri in oidcSettings
    path: "/oidc-callback-error",
    name: "oidcCallbackError",
    component: OidcCallbackError,
    meta: {
      isPublic: true,
      title: "Error",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    const app = document.getElementById("app");
    if (app) {
      app.scrollIntoView();
    }
  },
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;
