<template>
  <div id="pdf-container" style="height: 100%; width: 100%">
    <ejs-pdfviewer
      :id="pdfViewerId"
      :serviceUrl="serviceUrl"
      :documentPath="docId"
      :ajaxRequestSettings="ajaxRequestSettings"
      :zoomMode="zoomMode"
      ref="pdfViewerRef">
    </ejs-pdfviewer>
  </div>
</template>

<script>
  import { ref, computed, onMounted, nextTick } from "vue";
  import {
    PdfViewerComponent,
    Toolbar,
    Navigation,
    ThumbnailView,
    TextSelection,
    Magnification,
    TextSearch,
  } from "@syncfusion/ej2-vue-pdfviewer";
  import store from "../store";
import { set } from 'vue-gtag';

  export default {
    name: "PdfViewer",
    components: {
      "ejs-pdfviewer": PdfViewerComponent,
    },
    props: {
      docId: String,
      stipulationId: {
        required: false,
        type: Number,
      },
    },
    setup(props) {
      const oidcUser = computed(
        () => store.getters["oidcStore/oidcAccessToken"]
      );
      const pdfViewerId = computed(() =>
        props?.stipulationId && props?.stipulationId > 0
          ? `pdfViewer_${props.docId}_${props?.stipulationId}`
          : `pdfViewer_${props.docId}`
      );
      const ajaxRequestSettings = ref({
        ajaxHeaders: [
          {
            headerName: "Authorization",
            headerValue: `Bearer ${oidcUser.value}`,
          },
        ],
      });

      const serviceUrl = ref("/api/pdf");
      const zoomMode = ref("FitToPage");
      const pdfViewerRef = ref(null);


      onMounted(() => {
        if (pdfViewerRef.value) {
          pdfViewerRef.value.dataBind();
          pdfViewerRef.value.load(props.docId);
		  nextTick(() => {
			// pdfViewerRef.value.load(props.docId);
			setTimeout(() => {
				pdfViewerRef.value.load(props.docId);
			}, 1000);
          });
        }

      });

      return {
        ajaxRequestSettings,
        serviceUrl,
        zoomMode,
        pdfViewerId,
        pdfViewerRef,
      };
    },

    provide: {
      PdfViewer: [
        Toolbar,
        Navigation,
        ThumbnailView,
        TextSelection,
        Magnification,
        TextSearch,
      ],
    },
  };
</script>

<style scoped></style>
