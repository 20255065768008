import { Permissions, PermissionLevels } from "@/models/permissions";
import { computed } from "vue";
import { usePermissionsStore } from "@/store/store/permissionsStore";

export function usePermissionsAccess() {
    const permissionsStore = usePermissionsStore();

    const hasPermission = (permissionIds: Permissions[], permissionLevel: PermissionLevels) => {

        const userRoles = permissionsStore.currentUser.roles;

        if (!Array.isArray(userRoles)) {
            return false;
        }

        for (const role of userRoles) {
            if (role.rolePermissions?.some(rp => permissionIds.includes(rp.permissionId) && hasPermissionLevel(rp.permissionLevel, permissionLevel))) {
                return true;
            }
        }

        return false;
    };

    const hasPermissionLevel = (permissionLevel: PermissionLevels, targetPermissionLevel: PermissionLevels) => {
        return (permissionLevel & targetPermissionLevel) !== 0;
    };

    const canRead = (permissions: Permissions[]) =>
        computed(() => hasPermission(permissions, PermissionLevels.Read));
    const canUpdate = (permissions: Permissions[]) =>
        computed(() => hasPermission(permissions, PermissionLevels.Update));
    const canCreate = (permissions: Permissions[]) =>
        computed(() => hasPermission(permissions, PermissionLevels.Create));
    const canDelete = (permissions: Permissions[]) =>
        computed(() => hasPermission(permissions, PermissionLevels.Delete));

    return {
        hasPermission,
        canRead,
        canUpdate,
        canCreate,
        canDelete,
    };
}