import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { LOSDocument } from "@/models/opal";

interface PdfModalState {
	active: boolean;
	title?: string;
	description?: string;
	docId?: string;
	fullscreen: boolean;
	pageCount?: number;
	documents?: any;
	application?: any;
	splitting: boolean;
  buttons?: any;
}

export const usePdfViewerModalStore = defineStore('pdfViewerModal', () => {
  const pdfModal = ref<PdfModalState>({} as PdfModalState);
  const pdfModalActive = computed<boolean>(() =>  pdfModal.value.active);
  
  function openPdfModal(options: PdfModalState) {
    closePdfModal();

    pdfModal.value.active = true;
    pdfModal.value.title = options.title || "";
    pdfModal.value.description = options.description || "";
    pdfModal.value.docId = options.docId?.toString() || "";
    pdfModal.value.buttons = options.buttons || {};
    pdfModal.value.fullscreen = options.fullscreen || false;
    pdfModal.value.documents = options.documents || undefined;
    pdfModal.value.application = options.application || undefined;
    pdfModal.value.splitting = options.splitting || false;
  }

  function closePdfModal() {
    pdfModal.value.active = true;
  }

  return {
    pdfModal,
    openPdfModal,
    closePdfModal,
    pdfModalActive
  }
});