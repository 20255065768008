<template>
	<router-view />
</template>

<script>
	import { defineComponent } from 'vue';
	import { usePermissionsStore } from '@/store/store/permissionsStore';
	import { mapGetters, mapState } from 'vuex';

	export default defineComponent({
		props: [],
		computed: {
			...mapState(['idleVue']),
			...mapGetters('oidcStore', ['oidcIsAuthenticated']),
		},
		methods: {
			checkVersionNumber() {
				//////////////////////////////////////////////////////////////////
				// this function can be used to clear user's localStorage cache //
				// when necessary by incrementing the latestVersion variable    //
				//////////////////////////////////////////////////////////////////
				// increase this variable to clear the user's localStorage cache
				const latestVersion = 7;
				// gets the users' current version number
				const userVersion = localStorage.getItem('cache.VersionNumber');
				// if the latest version of the application is higher than the users current version
				if (latestVersion > Number(userVersion)) {
					const userRoleBackup = localStorage.getItem('cache.UserRoles');
					// loop through each of the entries in localStorage
					Object.entries(localStorage).forEach(([key]) => {
						// find all entries which start with "cache."
						if (key.startsWith('cache.') && key !== 'cache.UserRoles') {
							// remove from localStorage
							localStorage.removeItem(key);
						}
					});
					if (userRoleBackup) {
						// set the userRoles back into localStorage
						localStorage.setItem('cache.UserRoles', userRoleBackup);
					}
					// set the version number in localStorage to the latest version
					localStorage.setItem(`cache.VersionNumber`, latestVersion);
				}
			},
			checkPermissions() {
				const permissionsStore = usePermissionsStore();
				permissionsStore.loadUserRolesFromStorage();
		},
	},
		watch: {
			$route: {
				immediate: true,
				handler(to) {
					this.checkVersionNumber();
					document.title = to.meta.title
						? `${to.meta.title} - Loan Origination System`
						: 'Loan Origination System';
				},
			},
		},
		mounted() {
			this.checkPermissions();
		},
	});
</script>
<style>

  @import '@syncfusion/ej2-base/styles/material.css';  
  @import '@syncfusion/ej2-buttons/styles/material.css';
  @import '@syncfusion/ej2-dropdowns/styles/material.css';  
  @import '@syncfusion/ej2-inputs/styles/material.css';  
  @import '@syncfusion/ej2-navigations/styles/material.css';
  @import '@syncfusion/ej2-popups/styles/material.css';
  @import '@syncfusion/ej2-splitbuttons/styles/material.css';
  @import '@syncfusion/ej2-lists/styles/material.css';
  @import '@syncfusion/ej2-vue-pdfviewer/styles/material.css';

	
  #pdfViewer {
    height: 640px;
  } 
</style>
