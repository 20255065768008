<template>
	<loader v-if="loading" />
	<page v-else>
		<div class="xpage-breadcrumbs">
			<router-link to="/dashboard" class="icon-home"></router-link>
			<span>></span> <router-link to="/bureaus">Bureaus</router-link>
		</div>

		<div class="xpage-inner">
			<h2>Bureaus</h2>
		</div>
	</page>
</template>

<script>
	import { defineComponent } from 'vue';
	import Page from '../components/layout/Page.vue';
	import Loader from '@/components/Loader.vue';
	import { $api } from '@/services/api';

	export default defineComponent({
		components: {
			Page,
			Loader,
		},
		data: () => ({
			loading: true,
		}),
		async mounted() {
			this.loading = false;
		},
	});
</script>

