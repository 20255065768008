import { computed } from 'vue';
import store from '@/store';

export function useIsCreditUnionUser() {
    return computed(() => {
        const user = store.state.oidcStore.user;
        return user ? isNaN(parseInt(user.los_access)) === false : false;
    });
}

export function isAdminUser() {
    const user = store.state.oidcStore.user;

    if (user === null) {
        return false;
    }
    
    const claimValue: string | null | undefined = user.los_access;

    return claimValue != null && claimValue != undefined && claimValue.toLowerCase() === 'yes';
}