// @ts-ignore
import store from "../store";
import router from "../router";
import { $api } from "../services/api";

export default function useSignOut() {
	
	async function signOut() {

		const oidcUser = store.getters["oidcStore/oidcUser"];

		// @ts-ignore
		store.dispatch("setValidSession", false);

		if (oidcUser) {

			await $api.signout(oidcUser.sub);

		}

		await store.dispatch("oidcStore/signOutOidcSilent");

		store.dispatch("oidcStore/removeOidcUser").then(async () => {

			router.push("/loggedout");

		}).catch((err) => {

			console.log("REMOVE OIDC USER ERROR", err);

		});
		
	}

	return { signOut };
}
