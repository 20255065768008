import { CustomPricing } from "./customPricing";

export interface ApplicantEvaluations {
    applicantId?: number;
    applicantTypeId?: number;
    creditScore?: number;
    creditScoreApproved?: number,
    creditId?: number;
    dti?: string;
    dtiApproved?: string;
    income?: string;
    incomeApproved?: string;
    employed?: string;
    employmentApproved?: string;
    locQualifiedAmnt?: string;
    locApproved?: string;
    isCreditScoreEval?: boolean;
    isDTIEval?: boolean;
    isEmploymentEval?: boolean;
    isIncomeEval?: boolean;
    creditEvaluation?: {
        creditAlerts?: string;
        creditKOs?: string;
        pricing?: Array<string>
    }
}

export interface Recommendation
{
    id?: number;
    name?: string;
}

export interface AnalystReview {
    applicationExceptionId?: number;
    applicationExceptionNoteId?: number;
    reviewBy?: string;
    reviewDateTime?: string;
    decisionId?: number;
    recommendationId?: number;
    comments?: string;
    isCustom?: boolean;
    exceptions?: Array<Exception>;
    isDisabled?: boolean;
}

export interface Exception
{
    exceptionTypeId: number;
    pricingTierIds?: number[];
    customPricing?: CustomPricing;
    locLimit?: string;
}


export interface OptionProgramTiers
{
    id?: number;
    name?: string;
}

export enum OptionProgramTierTypes
{
    ExistingPricingTier=1,
    CustomPricingTier=2
}

export enum ExceptionTypes {
    PricingDefault,
    LocLimitMod,
    PricingRateCreditScoreAdjustment,
    PricingRateRateMatchBeat,
    PricingRateOther,
}

export interface ProgramTier
{
    pricingTierId?: number;
    loanProgramId?: number;
    letterGradePricingTier?: string;
    scoreRange?: string;
    isSelected?: boolean;
    rateTypeId?: number;
    fixedRate?: string;
    variableRate?: string;
    rate?: string;
    margin?: string;
    index?: string;
    floor?: string;
    ceiling?: string;
}