
import store from "@/store";
import { Applicant } from "@/models/opal";
export default function useStipulations(): any {
  function replaceObjectWithId(arr: Array<any>, id: number, newObj: object) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    
    if (objWithIdIndex > -1) {
      arr[objWithIdIndex] = Object.assign({}, newObj);
    }
  
    return arr;
  }

  function updateStipulationObj(arr: Array<any>, id: number, newObj: object) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    if (objWithIdIndex > -1) {
      arr[objWithIdIndex] = Object.assign({}, newObj);
    }
  
    return arr;
  }
  function removeObjectWithId(arr: Array<any>, id: number) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
  
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
  
    return arr;
  }
  
  function resetStipForm(stipForm: any) {
    stipForm.stipulationTypeId = undefined;
    stipForm.stipulationStatusId = undefined;
    stipForm.stipulationStatusReasonId = undefined;
    stipForm.stipulationText = undefined;
    return stipForm;
  }
  function stipulationStatusReasonId(statusName: string) {

    let statusReason = "Open";
    switch (statusName) {
      case "Open":
        statusReason = "Open";
        break;
      case "Completed":
        statusReason = "Fulfilled"
        break;
      case "CU-Override":
        statusReason = "Override"
        break;
    }
    const statusReasonUpdate = store.state.types['StipulationStatusReasons']?.results?.find((s: any) => s.name.trim() === statusReason);
    return statusReasonUpdate.id;
  }
  function getApplicant(arr: any, applicantId: number) {
    return arr.find((a: Applicant) => a.id === applicantId);
  }
  return {
    getApplicant,
    updateStipulationObj,
    replaceObjectWithId,
    removeObjectWithId,
    resetStipForm,
    stipulationStatusReasonId
  }
}